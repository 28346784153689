import React, { useEffect, useState } from 'react'
import { Alert, Button, Drawer, Form, Input, message, notification, Popconfirm } from 'antd'
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import Container from '../../../components/Container'
import Main from '../../../components/Main'
import PageHeader from '../../../components/PageHeader'
import AgoraTable from '../../../components/Table';
import { useAuthUser } from 'react-auth-kit';
import axios from 'axios';

function TimeZone() {
    const auth = useAuthUser()
    const [timeZoneForm] = Form.useForm();
    const [state, setState] = useState({
        gvTimeZoneLoading: false,
        gvTimeZone: [],
        formOpen: false,
        formType: "Add",
        btnSubmitLoading: false,
        btnSubmitResStatus: "",
        btnSubmitResMsg: "",
        btnDeleteLoading: false,
    })
    const handleSetState = (newState) => {
        setState(prevState => ({
            ...prevState,
            ...newState,
        }))
    }
    useEffect(() => {
        getTimeZonePageLoad()
        // eslint-disable-next-line
    }, [])
    const getTimeZonePageLoad = () => {
        handleSetState({ gvTimeZoneLoading: true })
        axios.get(`/TimeZone/PageLoad`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    gvTimeZoneLoading: false,
                    gvTimeZone: resData.Data.gvTimeZone
                })
                return resData.Data
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }
    const handleOnFinishTimeZone = (formData) => {
        handleSetState({ btnSubmitLoading: true })
        if (state.formType === "Add") {
            axios.post(`/TimeZone/Create`, {
                timeZoneCode: formData.timeZoneCode,
                timeZoneDesc: formData.timeZoneDesc,
                createdBy: auth().userId,
            }).then(res => {
                let resData = res.data
                if (resData.Status === "True") {
                    handleSetState({
                        formOpen: false,
                        btnSubmitLoading: false,
                        btnSubmitResStatus: "",
                        btnSubmitResMsg: "",
                    });
                    getTimeZonePageLoad();
                    notification.success({
                        message: "Successful",
                        description: resData.Message
                    });
                } else {
                    handleSetState({
                        btnSubmitLoading: false,
                        btnSubmitResStatus: resData.Status,
                        btnSubmitResMsg: resData.Message,
                    });
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            })
        } else if (state.formType === "Edit") {
            axios.post(`/TimeZone/Update`, {
                timeZoneId: formData.timeZoneId,
                timeZoneCode: formData.timeZoneCode,
                timeZoneDesc: formData.timeZoneDesc,
                modifiedBy: auth().userId,
            }).then(res => {
                let resData = res.data
                if (resData.Status === "True") {
                    handleSetState({
                        formOpen: false,
                        btnSubmitLoading: false,
                        btnSubmitResStatus: "",
                        btnSubmitResMsg: "",
                    });
                    getTimeZonePageLoad();
                    notification.success({
                        message: "Successful",
                        description: resData.Message
                    });
                } else {
                    handleSetState({
                        btnSubmitLoading: false,
                        btnSubmitResStatus: resData.Status,
                        btnSubmitResMsg: resData.Message,
                    });
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            })
        }

    }
    const handleOnDeleteTimeZone = () => {
        handleSetState({ btnDeleteLoading: true })
        axios.post(`/TimeZone/Delete`, {
            timeZoneId: timeZoneForm.getFieldValue("timeZoneId"),
            modifiedBy: auth().userId,
        }).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    formOpen: false,
                    btnDeleteLoading: false,
                    btnSubmitResStatus: "",
                    btnSubmitResMsg: "",
                });
                getTimeZonePageLoad();
                notification.success({
                    message: "Successful",
                    description: resData.Message
                });
            } else {
                handleSetState({
                    btnDeleteLoading: false,
                    btnSubmitResStatus: resData.Status,
                    btnSubmitResMsg: resData.Message,
                });
            }
        }).catch(error => {
            console.log(error);
            message.error(error.message);
        })

    }

    const isAllowToManage = () => {
        if (auth().role === "Admin") {
            return true
        }
        return false;
    }

    return (
        <Main listBreadcrumb={[
            { key: "1", title: "Maintenance" },
            { key: "2", title: "Library" },
            { key: "3", title: "Time Zone" },
        ]}>
            <Container>
                <PageHeader title="Time Zone" subtitle="List of time zone details.">
                    {isAllowToManage() &&
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                timeZoneForm.resetFields()
                                handleSetState({
                                    formOpen: true,
                                    formType: "Add",
                                })
                            }}
                        >
                            New Time Zone
                        </Button>
                    }
                </PageHeader>
                <AgoraTable
                    loading={state.gvTimeZoneLoading}
                    dataSource={state.gvTimeZone}
                    rowKey="timeZoneId"
                    columns={[
                        {
                            title: 'Time Zone Code',
                            dataIndex: 'timeZoneCode',
                            key: 'timeZoneCode',
                            dataType: "text",
                            render: (text, record) => <div className="i-x-space-between">
                                <div>{text || "-"}</div>
                                <div className="i-x-right">
                                    {isAllowToManage() && <Button className="table-btn edit" size="small" style={{ marginRight: "10px" }}
                                        onClick={() => {
                                            timeZoneForm.setFieldsValue({
                                                timeZoneId: record.timeZoneId,
                                                timeZoneCode: record.timeZoneCode,
                                                timeZoneDesc: record.timeZoneDesc,
                                            });
                                            handleSetState({
                                                formOpen: true,
                                                formType: "Edit",
                                            })
                                        }}
                                    ><EditOutlined /> Edit</Button>
                                    }
                                </div>
                            </div>,
                        },
                        {
                            title: 'Description',
                            dataIndex: 'timeZoneDesc',
                            key: 'timeZoneDesc',
                            dataType: "text",
                            render: (text) => text || "-",
                        },
                    ]}
                />
            </Container>
            <Drawer title={`${state.formType} Time Zone`}
                width={window.innerWidth > 500 ? 500 : window.innerWidth - 80}
                open={state.formOpen}
                extra={
                    <Button type="primary"
                        loading={state.btnSubmitLoading}
                        onClick={() => timeZoneForm.submit()}
                        icon={state.formType === "Add" ? <PlusOutlined /> : <SaveOutlined />}
                    >
                        {state.formType === "Add" ? "Add" : "Update"}
                    </Button>
                }
                onClose={() => {
                    timeZoneForm.resetFields()
                    handleSetState({
                        formType: "Add",
                        formOpen: false,
                        btnSubmitResStatus: "",
                        btnSubmitResMsg: "",
                    })
                }}
            >
                <div className="i-y-100 i-y-space-between">
                    <Form
                        form={timeZoneForm}
                        name="name-title-form"
                        layout="vertical"
                        size="middle"
                        requiredMark={false}
                        onFinish={handleOnFinishTimeZone}
                    >
                        {state.btnSubmitResStatus !== ""
                            && <Alert type="error" showIcon style={{ margin: "0px 25px 20px 0px" }}
                                message={state.btnSubmitResMsg}
                            />
                        }
                        <Form.Item hidden={true} name="timeZoneId" ></Form.Item>
                        <Form.Item
                            label="Time Zone Code"
                            name="timeZoneCode"
                            tooltip="This is a required field"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter time zone code!',
                                },
                            ]}
                        >
                            <Input placeholder='e.g., SGD+8' />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="timeZoneDesc"
                            tooltip="This is a required field"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter time zone description!',
                                },
                            ]}
                        >
                            <Input placeholder='e.g., Singapore (UTC+8)' />
                        </Form.Item>
                    </Form>
                    {state.formType === "Edit"
                        ? <div>
                            <Popconfirm
                                title="Delete time zone"
                                description="Are you sure to delete this time zone?"
                                onConfirm={handleOnDeleteTimeZone}
                                okText="Yes"
                                okType="danger"
                            >
                                <Button type="primary" danger block loading={state.btnDeleteLoading}
                                    icon={<DeleteOutlined />}
                                >Delete Time Zone</Button>
                            </Popconfirm>
                        </div>
                        : ""
                    }
                </div>
            </Drawer>
        </Main>
    )
}

export default TimeZone;