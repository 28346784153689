import React, { useEffect, useState } from 'react';
import {
    AuditOutlined,
} from '@ant-design/icons';
import { Button, message } from 'antd';
import { Link } from 'react-router-dom';
import Main from '../../components/Main';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import Table from '../../components/Table';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';

function ListApproval() {
    const auth = useAuthUser()
    const [state, setState] = useState({
        gvVendorApprovalLoading: false,
        gvVendorApproval: [],
    })
    const handleSetState = (newState) => {
        setState(prevState => ({
            ...prevState,
            ...newState,
        }))
    }
    useEffect(() => {
        getVendorApprovalList()
        // eslint-disable-next-line
    }, [])
    const getVendorApprovalList = () => {
        handleSetState({ gvVendorApprovalLoading: true })
        axios.get(`/VendorApproval/PageLoad/${auth().role}`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    gvVendorApprovalLoading: false,
                    gvVendorApproval: resData.Data.gvVendorApprovalList
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }
    return (
        <>
            <Main listBreadcrumb={[
                { key: "1", title: "My Task" },
                { key: "2", title: "List Approval" },
            ]}>
                <Container>
                    <PageHeader
                        title="List Approval"
                        subtitle="Click approval button to review and approve vendor details."
                    />
                    <Table
                        loading={state.gvVendorApprovalLoading}
                        dataSource={state.gvVendorApproval}
                        columns={[
                            {
                                title: 'Application ID',
                                dataIndex: 'appID',
                                key: 'appID',
                                dataType: "text",
                                render: (text, record) => <div className="i-x-space-between">
                                    <div>REG{String("00000" + text).slice(-5) || "-"}</div>
                                    <div style={{ marginLeft: "25px" }}>
                                        <Link to={`./${record.appID}`}>
                                            <Button type="primary" size="small"><AuditOutlined style={{ fontSize: "13px" }} /> Approval</Button>
                                        </Link>
                                    </div>
                                </div>
                            },
                            {
                                title: 'Vendor Name',
                                dataIndex: 'vendorName',
                                key: 'vendorName',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Vendor Email',
                                dataIndex: 'vendorEmail',
                                key: 'vendorEmail',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Vendor From',
                                dataIndex: 'vendorFrom',
                                key: 'vendorFrom',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Vendor ID (BRN)',
                                dataIndex: 'vendorCompBRN',
                                key: 'vendorCompBRN',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Vendor Account',
                                dataIndex: 'vendorAccount',
                                key: 'vendorAccount',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Registration Date',
                                dataIndex: 'vendorRegistrationDate',
                                key: 'vendorRegistrationDate',
                                dataType: "date",
                                render: (text) => text || "-",
                            },
                        ]}
                    />
                </Container>
            </Main>
        </>
    )
}

export default ListApproval;
