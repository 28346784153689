import React, { useEffect, useState } from 'react'
import axios from 'axios'
import StickyBox from 'react-sticky-box'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Button, Divider, Form, Input, message, notification, Result, Steps, Tabs } from 'antd'
import {
    SaveOutlined,
} from '@ant-design/icons';
import { useAuthUser } from 'react-auth-kit'

import MainNoLogin from '../../../layouts/MainNoLogin'
import Main from '../../../components/Main';
import Container from '../../../components/Container'
import PageHeader from '../../../components/PageHeader'
import AgoraSelect from '../../../components/Select'
import WFNewApplication from '../../../components/Workflow/WFNewApplication'
import Attachment from '../../../components/Attachment'
import VendorInfo from './VendorInfo'
import Assessment from './Assessment'

import './Registration.css'

function MainRegister(props) {
    const auth = useAuthUser()
    const navigate = useNavigate()
    const { type } = props;
    const { urlId } = useParams();
    const [vendorInfoForm] = Form.useForm();
    const [assessmentForm] = Form.useForm();
    const [approvalForm] = Form.useForm();

    const [state, setState] = useState({
        appId: type === "approval" ? urlId : 0,
        previewOnly: false,
        formDdlData: [],
        btnVendorDraftLoading: false,
        btnVendorSubmitLoading: false,
        btnApprovalSubmitLoading: false,
        vendorAccountExist: false,
        vendorBrnExist: false,
    });

    const handleOnChangeCountry = (value, ddlName) => {
        axios.get(`Country/GetState/${value}`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                setState(prevState => ({
                    ...prevState,
                    formDdlData: {
                        ...prevState.formDdlData,
                        [ddlName]: resData.Data
                    },
                }))
                let singaporeId = state.formDdlData.ddlCountry?.filter(data => data.label.includes("Singapore"))[0].value
                if (value === singaporeId && ddlName === "ddlStateVendor") {
                    vendorInfoForm.setFieldsValue({
                        country: singaporeId,
                        state: resData.Data.filter(data => data.label.includes("Singapore"))[0].value,
                        city: "Singapore",
                    })
                    assessmentForm.setFieldsValue({
                        country: singaporeId,
                        state: resData.Data.filter(data => data.label.includes("Singapore"))[0].value,
                        city: "Singapore",
                    })
                } else {
                    vendorInfoForm.setFieldsValue({
                        country: value,
                    })
                    assessmentForm.setFieldsValue({
                        country: value,
                    })
                }
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }

    useEffect(() => {
        getPageLoad()
        // eslint-disable-next-line
    }, [])

    const stringToBoolean = (str) => {
        if (str === "1") {
            return true
        }
        return false;
    }

    const getPageLoad = async () => {
        let pageloadUrl = `NewApplication/PageLoad/Vendor/${urlId}`
        if (type === "approval") {
            pageloadUrl = `NewApplication/PageLoad/Staff/${urlId}`
        }
        await axios.get(pageloadUrl).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                const vendorInfo = resData.Data.newVendorDetails.tbVendor
                const formatedVendorInfo = {
                    vendorId: vendorInfo.VendorId,
                    vendorType: vendorInfo.VendorType && vendorInfo.VendorType !== "" ? vendorInfo.VendorType : "Local",
                    vendorBrn: vendorInfo.VendorBrn,
                    vendorAccount: vendorInfo.VendorAccount,
                    vendorTitle: vendorInfo.VendorTitle && vendorInfo.VendorTitle !== "" ? vendorInfo.VendorTitle : undefined,
                    vendorName: vendorInfo.VendorName,
                    vendorName2: vendorInfo.VendorName2,
                    vendorName3: vendorInfo.VendorName3,
                    vendorName4: vendorInfo.VendorName4,
                    acra: vendorInfo.Acra,
                    porg: vendorInfo.Porg && vendorInfo.Porg !== "" ? vendorInfo.Porg : getDefaultValue(resData.Data.ddlData.ddlPurchasingOrg),
                    accountGroup: vendorInfo.AccountGroup && vendorInfo.AccountGroup !== "" ? vendorInfo.AccountGroup : getDefaultValue(resData.Data.ddlData.ddlAccountGrp),
                    searchTerms1: vendorInfo.SearchTerms1,
                    searchTerms2: vendorInfo.SearchTerms2,
                    street1: vendorInfo.Street1,
                    street2: vendorInfo.Street2,
                    street3: vendorInfo.Street3,
                    postcode: vendorInfo.Postcode,
                    city: vendorInfo.City,
                    state: vendorInfo.State && vendorInfo.State !== 0 ? vendorInfo.State : undefined,
                    country: vendorInfo.Country && vendorInfo.Country !== 0 ? vendorInfo.Country : undefined,
                    timeZone: vendorInfo.TimeZone && parseInt(vendorInfo.TimeZone) !== 0 ? parseInt(vendorInfo.TimeZone) : undefined,
                    language: vendorInfo.Language && vendorInfo.Language !== "" ? vendorInfo.Language : getDefaultValue(resData.Data.ddlData.ddlLanguage),
                    commTelephone: vendorInfo.CommTelephone,
                    commMobile: vendorInfo.CommMobile,
                    fax: vendorInfo.Fax,
                    commEmail: vendorInfo.CommEmail,
                    remittanceEmail: vendorInfo.RemittanceEmail,
                    orderCurrency: vendorInfo.OrderCurrency && vendorInfo.OrderCurrency !== "" ? vendorInfo.OrderCurrency : undefined,
                    termsOfPayment: vendorInfo.TermsOfPayment && vendorInfo.TermsOfPayment !== "" ? vendorInfo.TermsOfPayment : getDefaultValue(resData.Data.ddlData.ddlPaymentTerm),
                    conMobile: vendorInfo.ConMobile,
                    paymentTermRemarks: vendorInfo.PaymentTermRemarks,
                    salesperson: vendorInfo.Salesperson,
                    salesTelephone: vendorInfo.SalesTelephone,
                    bankCountry: vendorInfo.BankCountry && vendorInfo.BankCountry !== "" ? vendorInfo.BankCountry : undefined,
                    accountName: vendorInfo.AccountName,
                    swiftCode: vendorInfo.SwiftCode,
                    bankAccountNo: vendorInfo.BankAccountNo,
                }
                vendorInfoForm.setFieldsValue(formatedVendorInfo);
                if (formatedVendorInfo.country) {
                    handleOnChangeCountry(formatedVendorInfo.country, "ddlStateVendor")
                }

                const assessmentData = resData.Data.newApplicationFormDetails
                const formatedAssessmentData = {
                    companyName1: assessmentData.companyName1,
                    companyName2: assessmentData.companyName2,
                    companyName3: assessmentData.companyName3,
                    companyName4: assessmentData.companyName4,
                    brn: assessmentData.brn,
                    address1: assessmentData.address1,
                    address2: assessmentData.address2,
                    address3: assessmentData.address3,
                    postcode: assessmentData.postcode,
                    city: assessmentData.city,
                    state: assessmentData.state && parseInt(assessmentData.state) !== 0 ? parseInt(assessmentData.state) : undefined,
                    country: parseInt(assessmentData.country) && parseInt(assessmentData.country) !== 0 ? parseInt(assessmentData.country) : undefined,
                    countryIncorporation: parseInt(assessmentData.countryIncorporation) && parseInt(assessmentData.countryIncorporation) !== 0 ? parseInt(assessmentData.countryIncorporation) : undefined,
                    websiteUrl: assessmentData.websiteURL,
                    facilityChk: stringToBoolean(assessmentData.facilityChk),
                    networkChk: stringToBoolean(assessmentData.networkChk),
                    mobileChk: stringToBoolean(assessmentData.mobileChk),
                    ithardwareChk: stringToBoolean(assessmentData.ithardwareChk),
                    itsoftwareChk: stringToBoolean(assessmentData.itsoftwareChk),
                    logisticsChk: stringToBoolean(assessmentData.logisticsChk),
                    professionalChk: stringToBoolean(assessmentData.professionalChk),
                    marketingChk: stringToBoolean(assessmentData.marketingChk),
                    othersChk: stringToBoolean(assessmentData.othersChk),
                    othersRemark: assessmentData.othersRemark,
                    susMatterChk: stringToBoolean(assessmentData.susMatterChk),
                    susMatterName: assessmentData.susMatterName,
                    susMatterTitle: assessmentData.susMatterTitle,
                    susMatterEmail: assessmentData.susMatterEmail,
                    susMatterPhoneNo: assessmentData.susMatterPhoneNo,
                    personFillChk: stringToBoolean(assessmentData.personFillChk),
                    personFillName: assessmentData.personFillName,
                    personFillTitle: assessmentData.personFillTitle,
                    personFillEmail: assessmentData.personFillEmail,
                    personFillPhoneNo: assessmentData.personFillPhoneNo,
                    ungcchk: stringToBoolean(assessmentData.ungcchk),
                    rbachk: stringToBoolean(assessmentData.rbachk),
                    airNoiseChk: assessmentData.airNoiseChk,
                    energyClimateChk: assessmentData.energyClimateChk,
                    waterWasteChk: assessmentData.waterWasteChk,
                    wasteChk: assessmentData.wasteChk,
                    useChk: assessmentData.useChk,
                    chemicalChk: assessmentData.chemicalChk,
                    occupationChk: assessmentData.occupationChk,
                    forcedChk: assessmentData.forcedChk,
                    workingHoursChk: assessmentData.workingHoursChk,
                    nonDiscChk: assessmentData.nonDiscChk,
                    freedomChk: assessmentData.freedomChk,
                    humanRightChk: assessmentData.humanRightChk,
                    fraudChk: assessmentData.fraudChk,
                    dataPrivacyChk: assessmentData.dataPrivacyChk,
                    supplierChk: assessmentData.supplierChk,
                    iSO14001Chk: stringToBoolean(assessmentData.iSO14001Chk),
                    iSO50001Chk: stringToBoolean(assessmentData.iSO50001Chk),
                    iSO45001Chk: stringToBoolean(assessmentData.iSO45001Chk),
                    iSO14064Chk: stringToBoolean(assessmentData.iSO14064Chk),
                    iSO27001Chk: stringToBoolean(assessmentData.iSO27001Chk),
                    iSO22301Chk: stringToBoolean(assessmentData.iSO22301Chk),
                    sA8000Chk: stringToBoolean(assessmentData.sA8000Chk),
                    bCropChk: stringToBoolean(assessmentData.bCropChk),
                    tAFEPChk: stringToBoolean(assessmentData.tAFEPChk),
                    bizChk: stringToBoolean(assessmentData.bizChk),
                    externalOtherChk: stringToBoolean(assessmentData.externalOtherChk),
                    externalOtherRmk: assessmentData.externalOtherRmk,
                    gHGChk: stringToBoolean(assessmentData.gHGChk),
                    gHGRmk: assessmentData.gHGRmk,
                    sbtiChk: stringToBoolean(assessmentData.sbtiChk),
                    climateChk: stringToBoolean(assessmentData.climateChk),
                    targetRmk: assessmentData.targetRmk,
                    gHGPubliclyChk: stringToBoolean(assessmentData.gHGPubliclyChk),
                    url: assessmentData.url,
                    regulatoryChk: stringToBoolean(assessmentData.regulatoryChk),
                    regulatoryRmk: assessmentData.regulatoryRmk,
                    supplierCodeOfConductChk: stringToBoolean(assessmentData.supplierCodeOfConductChk),
                    compareCodeOfConductChk: stringToBoolean(assessmentData.compareCodeOfConductChk),
                    codeOfConductRmk: assessmentData.codeOfConductRmk,
                }
                assessmentForm.setFieldsValue(formatedAssessmentData);

                approvalForm.setFieldsValue({
                    vendorBrn: vendorInfo.VendorBrn,
                    vendorAccount: vendorInfo.VendorAccount,
                })

                setState(prevState => ({
                    ...prevState,
                    appId: resData.Data.appId,
                    previewOnly: resData.Data.previewOnly,
                    formDdlData: resData.Data.ddlData,
                    formAssessmentInfo: formatedAssessmentData,
                    vendorBrn: vendorInfo.VendorBrn || "-",
                    vendorAccount: vendorInfo.VendorAccount,
                }))
                getAttachment(resData.Data.appId)
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }
    const getDefaultValue = (data) => {
        const getDefaultData = data.filter(data => data.defaultValue === true)
        if (getDefaultData.length > 0) {
            return getDefaultData[0].value
        }
        return undefined
    }

    const [listAttachment, setListAttachment] = useState([])
    const getAttachment = (appId) => {
        axios.get(`Attachment/PageLoad/${appId}`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                setListAttachment(resData.Data)
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }

    const handleOnSubmitForm = (formData) => {
        setCurrentStep(currentStep + 1)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const [currentStep, setCurrentStep] = useState(0);
    const tabs = [
        {
            tabId: "vendorInfo",
            title: 'Vendor Information',
            content: <VendorInfo
                vendorInfoForm={vendorInfoForm}
                assessmentForm={assessmentForm}
                handleOnSubmitForm={handleOnSubmitForm}
                previewOnly={type === "register" ? state.previewOnly : true}
                ddlData={state.formDdlData}
                handleOnChangeCountry={handleOnChangeCountry}
            />,
        },
        {
            tabId: "assessment",
            title: 'Sustainablility Self-Assessment',
            content: <Assessment
                form={assessmentForm}
                handleOnSubmitForm={handleOnSubmitForm}
                previewOnly={type === "register" ? state.previewOnly : true}
                ddlData={state.formDdlData}
                handleOnChangeCountry={handleOnChangeCountry}
                questionVersion={"2"}
            />,
        },
        {
            tabId: "attachment",
            title: 'Attachment',
            content: <Attachment
                appId={state.appId}
                title={`Upload Attachment`}
                previewOnly={type === "register" ? state.previewOnly : true}
                listAttachment={listAttachment}
                setListAttachment={setListAttachment}
                extraInfo={<><b>Important reminder</b>: Please submit official quotation/invoice, completed DCA form and ACRA (company registered in Singapore)</>}
            />,
        },
        {
            tabId: "success",
            title: 'Complete',
            content: <Result
                status="success"
                title="Thank you!"
                subTitle="Your form was submitted successfully."
                style={{ marginTop: "15px" }}
            />,
        }
    ]

    const handleOnClickVendorSubmitBtn = () => {
        setState(prevState => ({
            ...prevState,
            btnVendorSubmitLoading: true,
        }))
        const vendorData = vendorInfoForm.getFieldValue()
        const assessmentData = assessmentForm.getFieldValue()
        axios.post(`NewApplication/Submit`, {
            AppId: state.appId,
            vendorData,
            assessmentData,
        }).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                setCurrentStep(currentStep + 1)
                setState(prevState => ({
                    ...prevState,
                    btnVendorSubmitLoading: false,
                }))
            } else {
                setState(prevState => ({
                    ...prevState,
                    btnVendorSubmitLoading: false,
                }))
                message.error(resData.Message)
            }
        }).catch(error => {
            setState(prevState => ({
                ...prevState,
                btnVendorSubmitLoading: false,
            }))
            console.log(error)
            message.error(error.message)
        })
    };
    const handleOnClickVendorDraftBtn = () => {
        setState(prevState => ({
            ...prevState,
            btnVendorDraftLoading: true,
        }))
        const vendorData = vendorInfoForm.getFieldValue()
        const assessmentData = assessmentForm.getFieldValue()
        axios.post(`NewApplication/Draft`, {
            appId: state.appId,
            vendorData: {
                ...vendorData,
                country: vendorData.country ? vendorData.country : 0,
                state: vendorData.state ? vendorData.state : 0,
                language: vendorData.language ? vendorData.language : "",
                orderCurrency: vendorData.orderCurrency ? vendorData.orderCurrency : "",
                porg: vendorData.porg ? vendorData.porg : "",
                termsOfPayment: vendorData.termsOfPayment ? vendorData.termsOfPayment : "",
                vendorTitle: vendorData.vendorTitle ? vendorData.vendorTitle : "",
            },
            assessmentData: {
                ...assessmentData,
                country: assessmentData.country ? assessmentData.country : 0,
                state: assessmentData.state ? assessmentData.state : 0,
            },
        }).then(res => {
            setState(prevState => ({
                ...prevState,
                btnVendorDraftLoading: false,
            }))
            let resData = res.data
            if (resData.Status === "True") {
                notification.success({
                    message: "Successfully Draft",
                    description: resData.Message,
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            setState(prevState => ({
                ...prevState,
                btnVendorDraftLoading: false,
            }))
            console.log(error)
            message.error(error.message)
        })
    };

    const [requiredRemark, setRequiredRemark] = useState(false)
    const handleOnClickApprovalSubmitBtn = (formData) => {
        setState(prevState => ({
            ...prevState,
            btnApprovalSubmitLoading: true,
        }))
        axios.post(`VendorApproval/Submit`, {
            appId: state.appId,
            createdby: auth().userId,
            role: auth().role,
            vendorBrn: formData.vendorBrn,
            vendorAccount: formData.vendorAccount,
            remark: formData.approvalRemark,
            approveOrRejectStatus: formData.approvalAction,
        }).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                notification.success({
                    message: resData.Message
                })
                navigate(-1)
                setState(prevState => ({
                    ...prevState,
                    btnApprovalSubmitLoading: false,
                }))
            } else {
                setState(prevState => ({
                    ...prevState,
                    btnApprovalSubmitLoading: false,
                }))
                if (res.data.Data === "VendorAccountExist") {
                    setState(prevState => ({
                        ...prevState,
                        vendorAccountExist: true,
                    }))
                } else if (res.data.Data === "VendorBrnExist") {
                    setState(prevState => ({
                        ...prevState,
                        vendorBrnExist: true,
                    }))
                } else {
                    message.error(resData.Message)
                }

            }
        }).catch(error => {
            setState(prevState => ({
                ...prevState,
                btnApprovalSubmitLoading: false,
            }))
            console.log(error)
            message.error(error.message)
        })
    };

    return (
        state.previewOnly
            ? <div className="i-x-left">
                <div className="d-100 m-100">
                    <Container>
                        <PageHeader
                            title="Preview Registration Form"
                            subtitle="All the information down below related to vendor registred data."
                            style={{ marginBottom: "0px" }}
                        ></PageHeader>
                        <Tabs defaultActiveKey="1"
                            items={tabs
                                .filter((item) => item.tabId !== "success")
                                .map((item) => ({
                                    key: item.title,
                                    label: item.title,
                                    children: item.content
                                }))}
                        />
                    </Container>
                </div>
                {type === "approval"
                    && <div className="d-50 m-100">
                        <StickyBox offsetTop={15} offsetBottom={20} style={{ zIndex: 100 }}>
                            <Form
                                form={approvalForm}
                                name="approval-form"
                                size="middle"
                                layout="vertical"
                                requiredMark={'optional'}
                                onFinish={handleOnClickApprovalSubmitBtn}
                                scrollToFirstError
                                autoComplete={false}
                                onChange={() => {
                                    setState(prev => ({
                                        ...prev,
                                        vendorBrnExist: false,
                                        vendorAccountExist: false,
                                    }))
                                }}
                            >
                                <Container style={{ marginLeft: "20px" }}>
                                    <PageHeader
                                        title="Approval"
                                        subtitle="Please select action to do approval"
                                    >
                                        <Button type="primary"
                                            loading={state.btnApprovalSubmitLoading}
                                            htmlType="submit"
                                        >Submit</Button>
                                    </PageHeader>
                                    <div style={{ marginLeft: "20px" }}>
                                        <div style={{ marginBottom: "15px", marginRight: "20px" }}>
                                            <WFNewApplication appId={state.appId}>
                                                <Button className="table-btn default" block
                                                >Workflow History</Button>
                                            </WFNewApplication>
                                        </div>
                                        {(state.vendorAccountExist || state.vendorBrnExist)
                                            && <Alert type="error" showIcon style={{ marginRight: "20px", marginBottom: "15px" }}
                                                message={state.vendorBrnExist ? "Vendor ID (BRN) already exist!" : "Vendor account already exist!"}
                                            />
                                        }

                                        <Form.Item
                                            label="Vendor ID (BRN)"
                                            name="vendorBrn"
                                            hidden={auth().role === "Ap Finance" || auth().role === "Credit Control" ? false : true}
                                            rules={auth().role === "Ap Finance" || auth().role === "Credit Control"
                                                ? [
                                                    {
                                                        required: true,
                                                        message: 'Please enter vendor id!',
                                                    },
                                                ]
                                                : false
                                            }
                                        >
                                            <Input
                                                placeholder="Please type here"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Vendor Account No"
                                            name="vendorAccount"
                                            // hidden={auth().role === "Ap Finance" ? false : true}
                                            hidden={true}
                                            // rules={auth().role === "Ap Finance"
                                            //     ? [
                                            //         {
                                            //             required: true,
                                            //             message: 'Please enter vendor account no.!',
                                            //         },
                                            //     ]
                                            //     : false
                                            // } 
                                            rules={false}
                                        >
                                            <Input
                                                placeholder="Please type here"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Approval Action"
                                            name="approvalAction"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select approval action!',
                                                },
                                            ]}
                                        >
                                            <AgoraSelect
                                                allowClear
                                                placeholder="Please select an action"
                                                options={[
                                                    { value: 'Approve', label: 'Approve' },
                                                    { value: 'Reject', label: 'Reject' },
                                                ]}
                                                onChange={value => {
                                                    approvalForm.validateFields()
                                                    if (value === "Reject") {
                                                        setRequiredRemark(true)
                                                    } else {
                                                        setRequiredRemark(false)
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Remark"
                                            name="approvalRemark"
                                            rules={[
                                                {
                                                    required: requiredRemark,
                                                    message: 'Please enter remark for reject action!',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea
                                                placeholder="Type remark here"
                                                rows={3}
                                                maxLength={250}
                                                showCount
                                            />
                                        </Form.Item>
                                    </div>
                                </Container>
                            </Form>
                        </StickyBox>
                    </div>
                }
            </div>
            : <Container >
                <PageHeader
                    title="Registration Form"
                    subtitle="Enter all your information down below."
                    type="small"
                >

                    {state.previewOnly === false && tabs[currentStep].tabId !== "success"
                        && <Button type="primary"
                            icon={<SaveOutlined />}
                            style={{ backgroundColor: "#009688" }}
                            loading={state.btnVendorDraftLoading}
                            onClick={handleOnClickVendorDraftBtn}
                        >Draft</Button>
                    }
                    <Divider type="vertical" />
                    {currentStep > 0 && currentStep !== tabs.length - 1 && (
                        <Button
                            onClick={() => {
                                setCurrentStep(currentStep - 1);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            style={{ margin: '0 8px 0 0', }}
                        >
                            Previous
                        </Button>
                    )}
                    {currentStep === tabs.length - 1
                        && <Button
                            onClick={() => {
                                setState(prevState => ({
                                    ...prevState,
                                    previewOnly: true,
                                }))
                            }}
                        >
                            Preview
                        </Button>
                    }
                    {tabs[currentStep].tabId === "vendorInfo"
                        && <Button type="primary" onClick={() => vendorInfoForm.submit()}>
                            Next
                        </Button>
                    }
                    {tabs[currentStep].tabId === "assessment"
                        && <Button type="primary" onClick={() => assessmentForm.submit()}>
                            Next
                        </Button>
                    }
                    {tabs[currentStep].tabId === "attachment" ?
                        state.previewOnly !== true
                            ? <Button type="primary"
                                loading={state.btnVendorSubmitLoading}
                                onClick={handleOnClickVendorSubmitBtn}
                            >Submit</Button>
                            : <Button type="primary" onClick={() => setCurrentStep(currentStep + 1)}>
                                Next
                            </Button>
                        : ""
                    }
                </PageHeader>
                <StickyBox offsetTop={67} offsetBottom={20} style={{ zIndex: 100 }}>
                    <div className="register-stepper">
                        <Steps
                            type="navigation"
                            size="small"
                            current={currentStep}
                            items={tabs.map((item) => ({
                                key: item.title,
                                title: item.title,
                            }))}
                        />
                    </div>
                </StickyBox>
                <div className="register-content">
                    {tabs[currentStep].content}
                </div>
            </Container>
    )
}

export default function Registration(props) {
    const { type } = props;
    return (
        type === "register" ?
            <MainNoLogin title="Registration">
                <MainRegister type={type} />
            </MainNoLogin>
            : <Main listBreadcrumb={[
                { key: "1", title: "My Task" },
                { key: "2", title: "List Approval", link: "/mytask/listApproval" },
                { key: "2", title: "Review Form" },
            ]} >
                <MainRegister type={type} />
            </Main >
    )
}