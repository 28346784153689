import React from 'react'
import './Container.css';

const Container = ({ children, style }) => {
    return (
        <>
            <div className="page-container" style={style}>
                {children}
            </div>
        </>
    )
}

export default Container