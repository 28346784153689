import React, { useEffect, useState } from 'react'
import { Alert, Form, Input, Radio } from 'antd'
import FormSection from '../../../components/FormSection'
import AgoraSelect from '../../../components/Select'

const VendorInfo = ({ ddlData, form, handleOnSubmitForm, handleOnChangeCountry, previewOnly }) => {
    const formData = form.getFieldValue()
    const [requiredByAction, setRequiredByAction] = useState({
        paymentTerms: true,
    })
    const [countrySelected, setCountrySelected] = useState(false)
    useEffect(() => {
        setRequiredByAction(prev => ({
            ...prev,
            paymentTerms: formData.termsOfPayment === "Z090" ? false : true,
        }))
        setCountrySelected(formData.country ? true : false)
    }, [formData])
    return (
        <Form
            form={form}
            name="application-form"
            size="middle"
            layout="vertical"
            requiredMark={'optional'}
            onFinish={handleOnSubmitForm}
            scrollToFirstError
            disabled={previewOnly}
        >
            <FormSection title="Vendor Details">
                <div className="i-x-left i-x-wrap">
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Vendor Type"
                            name="vendorType"
                            rules={[
                                {
                                    required: true,
                                    message: 'Type is required!',
                                },
                            ]}
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value="Local">Local (Singapore)</Radio.Button>
                                <Radio.Button value="Oversea">Oversea</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Account Group"
                            name="accountGroup"
                            tooltip={<>If your business is under <b>Sole Proprietorship</b>, please select account group <b>Individual</b></>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Account Group is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Select an option"
                                options={ddlData.ddlAccountGrp}
                            />
                        </Form.Item>
                    </div>
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Vendor ID (BRN)"
                            name="vendorBrn"
                            tooltip="If your business is registered in Singapore. it is your ACRA UEN. Otherwise, state your BRN per where you are incorporated."
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Vendor ID!',
                                },
                            ]}
                        >
                            <Input placeholder="Company BRN" />
                        </Form.Item>
                        <Form.Item
                            label="Company Name"
                            name="vendorName"
                            tooltip="Please enter Company Name registered with ACRA"
                            style={{ marginBottom: "21px" }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Company name line 1 is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Company Name Line 1" maxLength={40} showCount />
                        </Form.Item>
                        <Form.Item
                            name="vendorName2"
                            style={{ marginBottom: "20px" }}
                        >
                            <Input placeholder="Company Name Line 2" maxLength={40} showCount />
                        </Form.Item>
                        <Form.Item
                            name="vendorName3"
                            style={{ marginBottom: "20px" }}
                        >
                            <Input placeholder="Company Name Line 3" maxLength={40} showCount />
                        </Form.Item>
                        <Form.Item
                            name="vendorName4"
                        >
                            <Input placeholder="Company Name Line 4" maxLength={40} showCount />
                        </Form.Item>
                    </div>
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Company BRN"
                            name="acra"
                            rules={[
                                {
                                    required: true,
                                    message: 'Company BRN is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Please type vendor id (BRN)" disabled />
                        </Form.Item>
                        <Form.Item
                            label="Title"
                            name="vendorTitle"
                            rules={[
                                {
                                    required: true,
                                    message: 'Title is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Select an option"
                                options={ddlData.ddlTitle}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Purchasing Organization"
                            name="porg"
                            rules={[
                                {
                                    required: true,
                                    message: 'Purchasing Organization is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Select an option"
                                options={ddlData.ddlPurchasingOrg}
                            />
                        </Form.Item>
                        <div className="i-x-left i-x-wrap">
                            <div className="d-50 m-100">
                                <Form.Item
                                    label="Search Terms 1"
                                    name="searchTerms1"
                                    tooltip="8 character from company name not include space."
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Search Terms 1 is required!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please type company name" disabled />
                                </Form.Item>
                            </div>
                            <div className="d-50 m-100">
                                <Form.Item
                                    label="Search Terms 2"
                                    name="searchTerms2"
                                    tooltip="8 character from vendor id (BRN) not include space."
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Search Terms 2 is required!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please type vendor id (BRN)" disabled />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>
            </FormSection>
            <FormSection title="Street Address">
                <div className="i-x-left i-x-wrap">
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Street/House Number"
                            name="street1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Street/House number is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Street Name / House Number" showCount maxLength={60} />
                        </Form.Item>
                        <Form.Item
                            label="Street 2"
                            name="street2"
                            tooltip="Please leave this field BLANK if there is no input to be provided"
                        >
                            <Input placeholder="Unit No" showCount maxLength={40} />
                        </Form.Item>
                        <Form.Item
                            label="Street 3"
                            name="street3"
                            tooltip="Please leave this field BLANK if there is no input to be provided"
                        >
                            <Input placeholder="Building Name" showCount maxLength={40} />
                        </Form.Item>
                        <Form.Item
                            label="Postcode"
                            name="postcode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Postcode is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Postcode" />
                        </Form.Item>
                    </div>
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Country"
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: 'Country is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Please select country"
                                options={ddlData.ddlCountry}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Region/State"
                            name="state"
                            rules={[
                                {
                                    required: true,
                                    message: 'State is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder={countrySelected === false ? "Please select country" : "Please select state"}
                                options={ddlData.ddlStateVendor} />
                        </Form.Item>
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: 'City is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Please type city here" />
                        </Form.Item>
                        <Form.Item
                            label="Time Zone"
                            name="timeZone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Time zone is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Please select Time zone"
                                options={ddlData.ddlTimezone}
                            />
                        </Form.Item>
                    </div>
                </div>
            </FormSection>
            <FormSection title="Communication">
                <div className="i-x-left i-x-wrap">
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Telephone No."
                            name="commTelephone"
                            tooltip="Please leave this field BLANK if there is no input to be provided"
                        >
                            <Input placeholder="Telephone" />
                        </Form.Item>
                        <Form.Item
                            label="Mobile Phone No."
                            name="commMobile"
                            tooltip="Please leave this field BLANK if there is no input to be provided"
                        >
                            <Input placeholder="Mobile Phone" />
                        </Form.Item>
                        <Form.Item
                            label="Fax No."
                            name="fax"
                            tooltip="Please leave this field BLANK if there is no input to be provided"
                        >
                            <Input placeholder="Fax No." />
                        </Form.Item>
                    </div>
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: 'Language is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Select an option"
                                options={ddlData.ddlLanguage}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="commEmail"
                            rules={[
                                {
                                    required: true,
                                    message: 'Email is required!',
                                },
                                {
                                    type: "email",
                                    message: 'Please enter valid email!',
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            label="Email (For Remittance Details)"
                            name="remittanceEmail"
                            rules={[
                                {
                                    required: true,
                                    message: 'Remittance email is required!',
                                },
                                {
                                    type: "email",
                                    message: 'Please enter valid email!',
                                },
                            ]}
                        >
                            <Input placeholder="Remittance Email" />
                        </Form.Item>
                    </div>
                </div>
            </FormSection>
            <FormSection title="Condition">
                <div className="i-x-left i-x-wrap">
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Terms of Payment"
                            name="termsOfPayment"
                            rules={[
                                {
                                    required: true,
                                    message: 'Terms of Payment is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Select an option"
                                options={ddlData.ddlPaymentTerm}
                                onChange={value => {
                                    setRequiredByAction(prev => ({
                                        ...prev,
                                        paymentTerms: value === "Z090" ? false : true,
                                    }))
                                }}
                            />
                        </Form.Item>
                        {requiredByAction.paymentTerms
                            && <Alert type="warning" showIcon message={<span>You <b>select</b> terms of payment <b>other than 90 days</b>, please type your <b>remark</b> below!</span>}
                                style={{ margin: "0 20px 15px 0" }}
                            />
                        }
                        <Form.Item
                            label="Terms of Payment Remarks"
                            name="paymentTermRemarks"
                            rules={[
                                {
                                    required: requiredByAction.paymentTerms,
                                    message: 'Remarks is required!',
                                },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={250} rows={3} placeholder="Type remark here" />
                        </Form.Item>
                    </div>
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Order Currency"
                            name="orderCurrency"
                            rules={[
                                {
                                    required: true,
                                    message: 'Order Currency is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Select an option"
                                options={ddlData.ddlCurrency}
                            />
                        </Form.Item>
                    </div>
                </div>
            </FormSection>
            <FormSection title="Sales Data">
                <div className="i-x-left i-x-wrap">
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Salesperson"
                            name="salesperson"
                            rules={[
                                {
                                    required: true,
                                    message: 'Sales person is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Salesperson" />
                        </Form.Item>

                        <Form.Item
                            label="Phone No."
                            name="salesTelephone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Phone No. is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Phone No." />
                        </Form.Item>
                    </div>
                    <div className="d-50 m-100"></div>
                </div>
            </FormSection>
            <FormSection title="Bank Details">
                <div className="i-x-left i-x-wrap">
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Bank Country"
                            name="bankCountry"
                            rules={[
                                {
                                    required: true,
                                    message: 'Bank country is required!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Please select Bank Country"
                                options={ddlData.ddlBankCountry}
                            />

                        </Form.Item>
                        <Form.Item
                            label="Account Name"
                            name="accountName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Account name is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Account Name" maxLength={60} showCount />
                        </Form.Item>
                        <Form.Item
                            label="Swift Code"
                            name="swiftCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Swift code is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Swift Code" maxLength={15} showCount />
                        </Form.Item>
                    </div>
                    <div className="d-50 m-100">
                        <Form.Item
                            label="Bank Account Number"
                            name="bankAccountNo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Bank account number is required!',
                                },
                            ]}
                        >
                            <Input placeholder="Bank Account Number" maxLength={25} showCount />
                        </Form.Item>
                    </div>
                </div>
            </FormSection>
        </Form>
    )
}

export default VendorInfo