import React, { useState } from 'react';
import './Main.css';
import Header from './Header';
import SideMenu from './SideMenu';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';

function Main({ activeMenu, children }) {
    const [collapsed, setCollapsed] = useState(false)

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }
    return (
        <Layout>
            <SideMenu activeMenu={activeMenu} collapsed={collapsed} setCollapsed={setCollapsed} />
            <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 300, transition: "all 0.2s" }}>
                <Header collapsed={collapsed} toggleNavbar={() => toggleNavbar()} />
                <Content
                    style={{
                        padding: "0px 25px 25px",
                        minHeight: 'calc(100vh - 70px)',
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default Main;