import React, { useState } from 'react'
import { Table, Input } from 'antd';
import dayjs from 'dayjs';

import './Table.css'

function AgoraTable(props) {
    const { headerFilter, type, columns, dataSource, ...rest } = props;

    let dataSourceWithKey = dataSource.map((data, index) => ({
        key: index,
        ...data
    }))

    const handleTextSorting = (a, b, columnKey, type) => {
        var textA = type !== "date" ? a[columnKey].toUpperCase() : a[columnKey] && a[columnKey] !== "-" ? dayjs(a[columnKey], "DD/MM/YYYY").unix() : 0;
        var textB = type !== "date" ? b[columnKey].toUpperCase() : b[columnKey] && b[columnKey] !== "-" ? dayjs(b[columnKey], "DD/MM/YYYY").unix() : 0;
        if (textA < textB) return type !== "date" ? -1 : 1;
        if (textA > textB) return type !== "date" ? 1 : -1;
        return 0; // names must be equal
    }

    const [txtSearchBy, setTxtSearchBy] = useState("")
    const getTableData = (data) => {
        const baseData = data || [];
        const value = txtSearchBy;
        const filterTable = baseData.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        return filterTable
    }

    return (
        <>
            <div className="agora-table-layout">
                {type !== "simple" && <div className="agora-table-header i-x-left i-x-wrap">
                    {headerFilter}
                    <div className="d-30 t-50 m-100">
                        <Input.Search
                            className="agora-table-header-search"
                            placeholder="Search by..."
                            onSearch={value => setTxtSearchBy(value)}
                            onChange={e => setTxtSearchBy(e.target.value)}
                        />
                    </div>
                </div>
                }
                <Table className="agora-table"
                    bordered={false}
                    pagination={type !== "simple" && {
                        position: ['topRight'],
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        className: "agora-table-pagination",
                        size: window.innerWidth > 767 ? "default" : "small"
                    }}
                    columns={columns.map(option => ({
                        sorter: (a, b) => handleTextSorting(a, b, option.key, option.dataType),
                        ...option,
                    }))}
                    dataSource={getTableData(dataSourceWithKey)}
                    {...rest}
                />
            </div>
        </>
    )
}

export default AgoraTable