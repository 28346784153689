import React from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Navigate } from 'react-router-dom';

const Home = () => {
    const auth = useAuthUser();

    let redirectPath;

    switch (auth().role) {
        case "Admin":
            redirectPath = "/report/vendorlisting";
            break;
        case "Requester":
            redirectPath = "/mytask/createregistrationlink";
            break;
        default:
            redirectPath = "/mytask/listapproval";
    }

    return <Navigate to={redirectPath} />;
};

export default Home;
