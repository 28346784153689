import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd';
import { useAuthUser } from 'react-auth-kit';

import AgoraLogo from "../assets/images/Agora_Header.png";
import AgoraLogoSmall from "../assets/images/agora_logo_small.png";
import { SideMenuFilterByRole } from '../utils/SideMenuFilter';

import "./SideMenu.css"

const { Sider } = Layout;

const SideMenu = ({ activeMenu, collapsed, setCollapsed }) => {
    const auth = useAuthUser()
    const role = auth().role
    const [sideMenuList, setSideMenuList] = useState([])

    useEffect(() => {
        setSideMenuList(SideMenuFilterByRole(role))
    }, [role])

    return (
        <Sider
            className="sidemenu"
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={300}
            theme={'dark'}
            breakpoint="lg"
            onBreakpoint={(broken) => {
                setCollapsed(broken)
            }}
        >
            <div className="sidemenu-logo i-x-center i-y-center">
                {collapsed
                    ? <img src={AgoraLogoSmall} alt="Agora logo" style={{ height: "40px" }} />
                    : <img src={AgoraLogo} alt="Agora logo" />
                }
            </div>
            <Menu
                className="sidemenu-menu"
                theme="dark"
                mode="inline"
                inlineIndent={13}
                defaultOpenKeys={['adminpanel']}
                style={{ borderRight: "unset" }}
                selectedKeys={activeMenu && [activeMenu]}
                items={sideMenuList}
            />
        </Sider>
    )
}

export default SideMenu