import React from 'react'
import { Link } from 'react-router-dom'

function Question({ no, text, info, link, guide, required, children }) {
    return (
        <>
            <div className="question-container i-x-left">
                <div className="question-no">{no}.</div>
                <div className="question-body">
                    <div className="question-wrap">
                        <div className={`question-text ${required !== false && "required"}`}>{text}</div>
                        <div className="question-info">{info}</div>
                        <div className="question-info"><Link to={link} target="_blank" rel="noopener noreferrer">{link}</Link></div>
                    </div>
                    <div className="question-input">
                        <div className="question-guide">{guide}</div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Question