
import React, { useState } from 'react';
import { Alert, Button, Form, Input } from 'antd';
import Main from '../../components/Main';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';

function CreateRegistrationLink() {
    const auth = useAuthUser()
    const [invitationForm] = Form.useForm();
    const [sendInvitationLoading, setSendInvitationLoading] = useState(false)
    const sendInvtResDataInt = {
        status: "",
        message: "",
        data: "",
    }
    const [sendInvtResData, setSendInvtResData] = useState(sendInvtResDataInt)
    const onSendInvitation = (formData) => {
        setSendInvitationLoading(true)
        axios.post(`/GenerateLink/VendorRegLink`, {
            email: formData.email,
            createdBy: auth().userId,
        }).then(res => {
            setSendInvitationLoading(false)
            if (res.data.Status === "True") {
                invitationForm.resetFields()
                setSendInvtResData({
                    status: "success",
                    message: res.data.Message,
                    data: res.data.Data
                })
            } else {
                setSendInvtResData({
                    status: "fail",
                    message: res.data.Message
                })
            }
        }).catch(error => {
            setSendInvitationLoading(false)
            console.log(error)
            setSendInvtResData({
                status: "fail",
                message: error.message
            })
        })
    };
    return (
        <Main listBreadcrumb={[
            { key: "1", title: "My Task" },
            { key: "2", title: "Create Registration Link" }
        ]}>
            <div className="i-x-left">
                <div className="d-50 m-100">
                    <Container>
                        <Form
                            name="invite"
                            form={invitationForm}
                            autoComplete="off"
                            layout="vertical"
                            size="middle"
                            requiredMark={false}
                            onFinish={onSendInvitation}
                            onChange={() => setSendInvtResData(sendInvtResDataInt)}
                        >
                            <PageHeader
                                title="Create Registration Link"
                                subtitle="Please enter vendor email to send registration link to vendor email."
                            >
                                <Button type="primary" htmlType="submit"
                                    loading={sendInvitationLoading}
                                >
                                    Send Link
                                </Button>
                            </PageHeader>
                            <div style={{ paddingLeft: "20px" }}>
                                <Form.Item
                                    label="Vendor Email"
                                    name="email"
                                    tooltip="This is a required field"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please key in vendor email!',
                                        },
                                        {
                                            // eslint-disable-next-line
                                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: 'Please enter valid email!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                {sendInvtResData.status !== ""
                                    ? sendInvtResData.status === "success"
                                        ? <Alert message={sendInvtResData.message} type="success" showIcon style={{ marginRight: "25px", marginBottom: "20px" }} />
                                        : <Alert message={sendInvtResData.message} type="error" showIcon style={{ marginRight: "25px", marginBottom: "20px" }} />
                                    : ""
                                }
                            </div>
                        </Form>
                    </Container>
                </div>
            </div>
        </Main>
    )
}

export default CreateRegistrationLink;
