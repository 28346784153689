import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useSignOut, useAuthUser } from 'react-auth-kit'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
    CaretDownOutlined,
} from '@ant-design/icons';
import { Layout, Dropdown } from 'antd';
import "./Header.css"

const { Header } = Layout;

const MainHeader = ({ collapsed, toggleNavbar }) => {
    const userDetails = useAuthUser()
    const signOut = useSignOut()
    const navigate = useNavigate()

    const profileList = [
        {
            key: '1',
            icon: <UserOutlined />,
            label: <Link to="/userprofile">Profile</Link>,
        },
        {
            key: '2',
            icon: <LogoutOutlined />,
            label: "Logout",
        },
    ];
    const onClickProfileItem = ({ key }) => {
        if (key === "2") {
            signOut()
            navigate("/")
        }
    }

    return (
        <Header
            className={`i-x-space-between i-y-center ${collapsed ? "header-collapsed" : ""}`}
            style={{
                padding: 0,
                background: '#ffffff',
                borderBottom: "1px solid #eeeeee",
                lineHeight: "unset",
                height: "70px"
            }}
        >
            <div className="header-left  i-x-left i-y-center">
                <div className="header-menu-toggle"
                    onClick={toggleNavbar}>
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </div>
                <div className="header-title">
                    <div className="header-title1">VRMS</div>
                    <div className="header-title2">Vendor Registration Management System</div>
                </div>
            </div>
            {/* <div className="header-middle i-x-center i-y-center">
            </div> */}
            <div className="header-right i-x-right i-y-center">
                <Dropdown
                    trigger={['click']}
                    arrow
                    menu={{
                        items: profileList,
                        onClick: onClickProfileItem,
                    }}
                    placement="bottomRight"
                >
                    <div className="user-profile i-x-left i-y-center">
                        <div className="user-profile-icon i-x-center i-y-center">
                            <UserOutlined />
                        </div>
                        <div className="">
                            <div className="user-profile-username">Hi, {userDetails().fullName || "-"}</div>
                            <div className="user-profile-role">{userDetails().role || "-"}</div>
                        </div>
                        <div className="user-profile-caret">
                            <CaretDownOutlined />
                        </div>
                    </div>
                </Dropdown>
            </div>
        </Header >
    )
}

export default MainHeader