import Home from "../pages/Home";
import UserProfile from "../pages/UserProfile";

import CreateRegistrationLink from "../pages/MyTask/CreateRegistrationLink";
import ListApproval from "../pages/MyTask/ListApproval";
import RegistrationApproval from "../pages/Form/Registration";

import VendorList from "../pages/Report/VendorList";
import VendorDetails from "../pages/Report/VendorDetails";

import Users from "../pages/Maintenance/Users";
import Country from "../pages/Maintenance/AdminPanel/Country";
import State from "../pages/Maintenance/AdminPanel/State";
import PurchaseOrg from "../pages/Maintenance/AdminPanel/PurchaseOrg";
import AccGroup from "../pages/Maintenance/AdminPanel/AccGroup";
import Currency from "../pages/Maintenance/AdminPanel/Currency";
import Language from "../pages/Maintenance/AdminPanel/Language";
import PaymentTerms from "../pages/Maintenance/AdminPanel/PaymentTerms";
import NameTitle from "../pages/Maintenance/AdminPanel/NameTitle";
import DocumentType from "../pages/Maintenance/AdminPanel/DocumentType";
import TimeZone from "../pages/Maintenance/AdminPanel/TimeZone";

const PrivateRoutes = [
    {
        menuKey: 'home',
        path: '/home',
        element: <Home />,
    },
    {
        menuKey: 'userprofile',
        path: '/userprofile',
        element: <UserProfile />,
    },

    //My Task
    {
        menuKey: 'mytask_createregistrationlink',
        path: '/mytask/createregistrationlink',
        element: <CreateRegistrationLink />,
    },
    {
        menuKey: 'mytask_listapproval',
        path: '/mytask/listapproval',
        element: <ListApproval />,
    },
    {
        menuKey: 'mytask_listapproval',
        path: '/mytask/listapproval/:urlId',
        element: <RegistrationApproval type="approval" />,
    },

    //Vendor
    {
        menuKey: 'report_vendorlisting',
        path: '/report/vendorlisting',
        element: <VendorList />,
    },
    {
        menuKey: 'report_vendorlisting',
        path: '/report/vendorlisting/:vendorId',
        element: <VendorDetails />,
    },

    //Maintenance
    {
        menuKey: 'maintenance_user',
        path: '/maintenance/user',
        element: <Users />,
    },
    {
        menuKey: 'maintenance_adminpanel_country',
        path: '/maintenance/adminpanel/country',
        element: <Country />,
    },
    {
        menuKey: 'maintenance_adminpanel_state',
        path: '/maintenance/adminpanel/state',
        element: <State />,
    },
    {
        menuKey: 'maintenance_adminpanel_accgroup',
        path: '/maintenance/adminpanel/accgroup',
        element: <AccGroup />,
    },
    {
        menuKey: 'maintenance_adminpanel_currency',
        path: '/maintenance/adminpanel/currency',
        element: <Currency />,
    },
    {
        menuKey: 'maintenance_adminpanel_language',
        path: '/maintenance/adminpanel/language',
        element: <Language />,
    },
    {
        menuKey: 'maintenance_adminpanel_paymentterms',
        path: '/maintenance/adminpanel/paymentterms',
        element: <PaymentTerms />,
    },
    {
        menuKey: 'maintenance_adminpanel_purchaseorg',
        path: '/maintenance/adminpanel/purchaseorg',
        element: <PurchaseOrg />,
    },
    {
        menuKey: 'maintenance_adminpanel_nametitle',
        path: '/maintenance/adminpanel/nametitle',
        element: <NameTitle />,
    },
    {
        menuKey: 'maintenance_adminpanel_documenttype',
        path: '/maintenance/adminpanel/documenttype',
        element: <DocumentType />,
    },
    {
        menuKey: 'maintenance_adminpanel_timezone',
        path: '/maintenance/adminpanel/timezone',
        element: <TimeZone />,
    },
];

export default PrivateRoutes;
