import React, { useEffect, useState } from 'react'
import { Alert, Button, Drawer, Form, Input, message, notification, Popconfirm } from 'antd'
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import Container from '../../../components/Container'
import Main from '../../../components/Main'
import PageHeader from '../../../components/PageHeader'
import AgoraTable from '../../../components/Table';
import { useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import AgoraSelect from '../../../components/Select';

function StateAddr() {
    const auth = useAuthUser()
    const [stateAddrForm] = Form.useForm();
    const [state, setState] = useState({
        ddlCountryAddr: [],
        gvStateAddrLoading: false,
        gvStateAddr: [],
        formOpen: false,
        formType: "Add",
        btnSubmitLoading: false,
        btnSubmitResStatus: "",
        btnSubmitResMsg: "",
        btnDeleteLoading: false,
    })
    const handleSetState = (newState) => {
        setState(prevState => ({
            ...prevState,
            ...newState,
        }))
    }
    useEffect(() => {
        getStateAddrPageLoad()
        // eslint-disable-next-line
    }, [])
    const getStateAddrPageLoad = () => {
        handleSetState({ gvStateAddrLoading: true })
        axios.get(`/State/PageLoad`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    gvStateAddrLoading: false,
                    gvStateAddr: resData.Data.gvState,
                    ddlCountryAddr: resData.Data.ddlCountry,
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }
    const handleOnFinishStateAddr = (formData) => {
        handleSetState({ btnSubmitLoading: true })
        if (state.formType === "Add") {
            axios.post(`/State/Create`, {
                stateCode: formData.stateCode,
                stateName: formData.stateName,
                countryId: formData.countryId,
                createdBy: auth().userId,
            }).then(res => {
                let resData = res.data
                if (resData.Status === "True") {
                    handleSetState({
                        formOpen: false,
                        btnSubmitLoading: false,
                        btnSubmitResStatus: "",
                        btnSubmitResMsg: "",
                    });
                    getStateAddrPageLoad();
                    notification.success({
                        message: "Successful",
                        description: resData.Message
                    });
                } else {
                    handleSetState({
                        btnSubmitLoading: false,
                        btnSubmitResStatus: resData.Status,
                        btnSubmitResMsg: resData.Message,
                    });
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            })
        } else if (state.formType === "Edit") {
            axios.post(`/State/Update`, {
                stateId: formData.stateId,
                stateName: formData.stateName,
                stateCode: formData.stateCode,
                countryId: formData.countryId,
                modifiedBy: auth().userId,
            }).then(res => {
                let resData = res.data
                if (resData.Status === "True") {
                    handleSetState({
                        formOpen: false,
                        btnSubmitLoading: false,
                        btnSubmitResStatus: "",
                        btnSubmitResMsg: "",
                    });
                    getStateAddrPageLoad();
                    notification.success({
                        message: "Successful",
                        description: resData.Message
                    });
                } else {
                    handleSetState({
                        btnSubmitLoading: false,
                        btnSubmitResStatus: resData.Status,
                        btnSubmitResMsg: resData.Message,
                    });
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            })
        }

    }
    const handleOnDeleteStateAddr = () => {
        handleSetState({ btnDeleteLoading: true })
        axios.post(`/State/Delete`, {
            stateId: stateAddrForm.getFieldValue("stateId"),
            modifiedBy: auth().userId,
        }).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    formOpen: false,
                    btnDeleteLoading: false
                });
                getStateAddrPageLoad();
                notification.success({
                    message: "Successful",
                    description: resData.Message
                });
            } else {
                handleSetState({
                    btnDeleteLoading: false,
                    btnSubmitResStatus: resData.Status,
                    btnSubmitResMsg: resData.Message,
                });
            }
        }).catch(error => {
            console.log(error);
            message.error(error.message);
        })

    }

    const isAllowToManage = () => {
        if (auth().role === "Admin") {
            return true
        }
        return false;
    }

    return (
        <Main listBreadcrumb={[
            { key: "1", title: "Maintenance" },
            { key: "2", title: "Library" },
            { key: "3", title: "State" },
        ]}>
            <Container>
                <PageHeader title="Region/State" subtitle="List of region/state details.">
                    {isAllowToManage() &&
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                stateAddrForm.resetFields();
                                handleSetState({
                                    formOpen: true,
                                    formType: "Add",
                                })
                            }}
                        >
                            New Region/State
                        </Button>
                    }
                </PageHeader>
                <AgoraTable
                    loading={state.gvStateAddrLoading}
                    dataSource={state.gvStateAddr}
                    rowKey="stateId"
                    columns={[
                        {
                            title: 'Region/State Name',
                            dataIndex: 'stateName',
                            key: 'stateName',
                            dataType: "text",
                            render: (text, record) => <div className="i-x-space-between">
                                <div>{text || "-"}</div>
                                <div className="i-x-right">
                                    {isAllowToManage() && <Button className="table-btn edit" size="small" style={{ marginRight: "10px" }}
                                        onClick={() => {
                                            stateAddrForm.setFieldsValue({
                                                stateId: record.stateId,
                                                countryId: record.countryId,
                                                stateCode: record.stateCode,
                                                stateName: record.stateName,
                                            });
                                            handleSetState({
                                                formOpen: true,
                                                formType: "Edit",
                                            })
                                        }}
                                    ><EditOutlined /> Edit</Button>}
                                </div>
                            </div>,
                        },
                        {
                            title: 'Region/State Code',
                            dataIndex: 'stateCode',
                            key: 'stateCode',
                            dataType: "text",
                            render: (text) => text || "-",
                        },
                        {
                            title: 'Country',
                            dataIndex: 'countryName',
                            key: 'countryName',
                            dataType: "text",
                            render: (text) => text || "-",
                        },
                    ]}
                />
            </Container>
            <Drawer title={`${state.formType} Region/State`}
                width={window.innerWidth > 500 ? 500 : window.innerWidth - 80}
                open={state.formOpen}
                extra={
                    <Button type="primary"
                        loading={state.btnSubmitLoading}
                        onClick={() => stateAddrForm.submit()}
                        icon={state.formType === "Add" ? <PlusOutlined /> : <SaveOutlined />}
                    >
                        {state.formType === "Add" ? "Add" : "Update"}
                    </Button>
                }
                onClose={() => {
                    stateAddrForm.resetFields()
                    handleSetState({
                        formType: "Add",
                        formOpen: false,
                        btnSubmitResStatus: "",
                        btnSubmitResMsg: "",
                    })
                }}
            >
                <div className="i-y-100 i-y-space-between">
                    <Form
                        form={stateAddrForm}
                        name="state-form"
                        layout="vertical"
                        size="middle"
                        requiredMark={'optional'}
                        onFinish={handleOnFinishStateAddr}
                    >
                        {state.btnSubmitResStatus !== ""
                            && <Alert type="error" showIcon style={{ margin: "0px 25px 20px 0px" }}
                                message={state.btnSubmitResMsg}
                            />
                        }
                        <Form.Item
                            label="Country"
                            name="countryId"
                            tooltip="This is a required field"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select country!',
                                },
                            ]}
                        >
                            <AgoraSelect
                                placeholder="Please select country"
                                options={state.ddlCountryAddr}
                            />
                        </Form.Item>
                        <Form.Item hidden={true} name="stateId" ></Form.Item>
                        <Form.Item
                            label="Region/State Code"
                            name="stateCode"
                            tooltip="This is a required field"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter region/state code!',
                                },
                            ]}
                        >
                            <Input placeholder='e.g., SG' />
                        </Form.Item>
                        <Form.Item
                            label="Region/State Name"
                            name="stateName"
                            tooltip="This is a required field"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter region/state name!',
                                },
                            ]}
                        >
                            <Input placeholder='e.g., Singapore' />
                        </Form.Item>
                    </Form>
                    {state.formType === "Edit"
                        ? <div>
                            <Popconfirm
                                title="Delete region/state"
                                description="Are you sure to delete this region/state?"
                                onConfirm={handleOnDeleteStateAddr}
                                okText="Yes"
                                okType="danger"
                            >
                                <Button type="primary" danger block loading={state.btnDeleteLoading}
                                    icon={<DeleteOutlined />}
                                >Delete Region/State</Button>
                            </Popconfirm>
                        </div>
                        : ""
                    }
                </div>
            </Drawer>
        </Main>
    )
}

export default StateAddr