import React, { useState } from 'react';
import axios from 'axios';
import { UserOutlined } from '@ant-design/icons';
import { Form, Input, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';

import MainLogin from '../layouts/MainLogin';


function ForgotPassword() {
    const [btnSendLoading, setBtnSendLoading] = useState(false)
    const [successReset, setSuccessReset] = useState(false)
    const [submitErrorMsg, setSubmitErrorMsg] = useState("")
    const onSubmit = (formData) => {
        setBtnSendLoading(true)
        axios.post(`/LoginVRMS/ForgotPassword`, {
            email: formData.email
        }).then(res => {
            setBtnSendLoading(false)
            if (res.data.Status === "True") {
                setSuccessReset(true)
            } else {
                setSubmitErrorMsg(res.data.Message)
            }
        }).catch(error => {
            console.log(error)
            setSubmitErrorMsg(error.message)
        })
    };
    return (
        <>
            {successReset
                ? <MainLogin
                    title="Email has been sent!"
                    subtitle="Please check your inbox and click the received link to reset your password."
                >
                    <Link to="/">
                        <Button className="d-100 m-100 login-form-button" type="primary" size="large">Login</Button>
                    </Link>
                </MainLogin>
                : <MainLogin
                    title="Forgot Password"
                    subtitle="Enter your email address below to receive password reset instruction."
                >
                    <Form
                        name="forgotpassword"
                        autoComplete={false}
                        layout="vertical"
                        requiredMark={false}
                        onFinish={onSubmit}
                    >
                        {submitErrorMsg !== ""
                            && < Alert message={submitErrorMsg} type="error" showIcon style={{ marginRight: "25px", marginBottom: "20px" }} />
                        }
                        <Form.Item
                            label="Email address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    // eslint-disable-next-line
                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter valid email!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Email Address"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button className="d-100 m-100 login-form-button" type="primary" size="large" htmlType="submit" loading={btnSendLoading}>
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="forgot-password-back">
                        Once you reset your password, you can't login using your old password anymore.
                        Remember your password now? <Link to="/"><Button type="link" style={{ padding: "unset", fontSize: "13px", height: "unset" }}>Back to Login</Button></Link>
                    </div>
                </MainLogin>
            }

            <style>{`
                    .forgot-password-back{
                        text-align: center;
                        color: #757575;
                    }
                `}</style>
        </>
    )
}

export default ForgotPassword