import React, { useState } from 'react';
import { Checkbox, Form, Input, Radio, Space } from "antd";
import { Link } from 'react-router-dom'
import AgoraSelect from '../../../components/Select';
import FormSection from '../../../components/FormSection'
import Questions from '../../../components/Questions'

import './Assessment.css'

const Assessment = ({ ddlData, form, handleOnChangeCountry, previewOnly }) => {
    const formData = form.getFieldValue()
    const [requiredByAction, setRequiredByAction] = useState({
        question7: formData.susMatterChk === false ? false : true,
        question12: formData.personFillChk === false ? false : true,
        question21: formData.gHGChk === false ? false : true,
        question24: formData.climateChk === false ? false : true,
        question26: formData.gHGPubliclyChk === false ? false : true,
        question28: formData.regulatoryChk === false ? false : true,
    })
    const SustainabilitySelfAssessment = [
        {
            type: "section",
            text: "A. Company Information"
        },
        {
            no: "1",
            text: "A-1. Name of organisation",
            info: "(Per your business registration)",
            input: <div style={{ marginTop: "10px" }}>
                <Form.Item
                    name="companyName1"
                    style={{ marginBottom: "15px" }}
                >
                    <Input placeholder="Company Name Line 1" disabled />
                </Form.Item>
                <Form.Item
                    name="companyName2"
                    style={{ marginBottom: "15px" }}
                >
                    <Input placeholder="Company Name Line 2" disabled />
                </Form.Item>
                <Form.Item
                    name="companyName3"
                    style={{ marginBottom: "15px" }}
                >
                    <Input placeholder="Company Name Line 3" disabled />
                </Form.Item>
                <Form.Item
                    name="companyName4"
                >
                    <Input placeholder="Company Name Line 4" disabled />
                </Form.Item>
            </div>
        },
        {
            no: "2",
            text: "A-2. Business Registration Number (BRN)",
            info: "(If your business is registered in Singapore, it is your ACRA UEN. Otherwise, state your BRN per where you are incorporated)",
            input: <Form.Item
                name="brn"
            >
                <Input placeholder="Company BRN" disabled />
            </Form.Item>
        },
        {
            no: "3",
            text: "A-3. Address",
            info: "(Per BRN above)",
            input: <div className="i-x-left i-x-wrap">
                <div className="d-50 m-100">
                    <Form.Item
                        name="address1"
                    >
                        <Input placeholder="Street Name" disabled />
                    </Form.Item>
                    <Form.Item
                        name="address2"
                    >
                        <Input placeholder="Unit No" disabled />
                    </Form.Item>
                    <Form.Item
                        name="address3"
                    >
                        <Input placeholder="Building Name" disabled />
                    </Form.Item>
                    <Form.Item
                        name="postcode"
                    >
                        <Input placeholder="Postcode" disabled />
                    </Form.Item>
                </div>
                <div className="d-50 m-100">
                    <Form.Item
                        name="country"
                    >
                        <AgoraSelect
                            disabled
                            placeholder="Country"
                            options={ddlData.ddlCountry}
                        />
                    </Form.Item>
                    <Form.Item
                        name="state"
                    >
                        <AgoraSelect
                            disabled
                            placeholder="State"
                            options={ddlData.ddlStateVendor}
                        />
                    </Form.Item>
                    <Form.Item
                        name="city"
                    >
                        <Input placeholder="City" disabled />
                    </Form.Item>
                </div>
            </div>
        },
        {
            no: "4",
            text: "A-4. Country of incorporation",
            info: "(Per BRN above)",
            input: <Form.Item
                name="countryIncorporation"
                rules={[
                    {
                        required: true,
                        message: 'Country of incorporation is required!',
                    },
                ]}
            >
                <AgoraSelect
                    options={ddlData.ddlCountry}
                />
            </Form.Item>
        },
        {
            no: "5",
            text: "A-5. Website URL",
            input: <Form.Item
                name="websiteUrl"
                rules={[
                    {
                        required: true,
                        message: 'Website URL is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "6",
            text: "A-6. Products or services supplied to StarHub Ltd?",
            info: "(Indicate main products and services)",
            guide: "Check all that apply.",
            input: <>
                <Form.Item name="facilityChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Facility and maintenance</Checkbox>
                </Form.Item>
                <Form.Item name="networkChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Network related maintenance</Checkbox>
                </Form.Item>
                <Form.Item name="mobileChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Mobile handset</Checkbox>
                </Form.Item>
                <Form.Item name="ithardwareChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>IT Hardware</Checkbox>
                </Form.Item>
                <Form.Item name="itsoftwareChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>IT Software</Checkbox>
                </Form.Item>
                <Form.Item name="logisticsChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Logistics services</Checkbox>
                </Form.Item>
                <Form.Item name="professionalChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Professional services</Checkbox>
                </Form.Item>
                <Form.Item name="marketingChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Marketing / Branding</Checkbox>
                </Form.Item>
                <div className="i-x-left i-y-center">
                    <Form.Item name="othersChk" valuePropName="checked">
                        <Checkbox>Other:</Checkbox>
                    </Form.Item>
                    <Form.Item name="othersRemark" style={{ width: "calc(100% - 114px)" }}>
                        <Input />
                    </Form.Item>
                </div>
            </>
        },
        {
            type: "section",
            text: "Contact person for sustainability matters in your organisation"
        },
        {
            no: "7",
            text: "A-7. Do you have a contact person for sustainability matters in your organisation?",
            guide: "Mark only one oval",
            input: <>
                <Form.Item
                    name="susMatterChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group
                        onChange={event => {
                            if (event.target.value === false) {
                                form.setFieldsValue({
                                    personFillChk: false
                                })
                                setRequiredByAction(prev => ({
                                    ...prev,
                                    question12: true,
                                }))
                            }
                            setRequiredByAction(prev => ({
                                ...prev,
                                question7: event.target.value,
                            }))
                        }}
                    >
                        <Space direction="vertical">
                            <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 8</span></Radio>
                            <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 13</span></Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "8",
            text: "Name",
            required: requiredByAction.question7,
            input: <Form.Item
                name="susMatterName"
                rules={[
                    {
                        required: requiredByAction.question7,
                        message: 'Name is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "9",
            text: "Title",
            required: false,
            input: <Form.Item
                name="susMatterTitle"
            >
                <Input />
            </Form.Item>
        },
        {
            no: "10",
            text: "Email",
            required: requiredByAction.question7,
            input: <Form.Item
                name="susMatterEmail"
                rules={[
                    {
                        required: requiredByAction.question7,
                        message: 'Email is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "11",
            text: "Phone Number",
            required: false,
            input: <Form.Item
                name="susMatterPhoneNo"
            >
                <Input />
            </Form.Item>
        },
        {
            type: "section",
            text: "Contact details of the person filling in this questionnaire"
        },
        {
            no: "12",
            text: "Same as sustainability contact person",
            guide: "Mark only one oval",
            input: <>
                <Form.Item
                    name="personFillChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group
                        disabled={!requiredByAction.question7}
                        onChange={event => {
                            setRequiredByAction(prev => ({
                                ...prev,
                                question12: !event.target.value
                            }))
                        }}
                    >
                        <Space direction="vertical">
                            <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 17</span></Radio>
                            <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 13</span></Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "13",
            text: "Name",
            required: requiredByAction.question12,
            input: <Form.Item
                name="personFillName"
                rules={[
                    {
                        required: requiredByAction.question12,
                        message: 'Name is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "14",
            text: "Title",
            required: requiredByAction.question12,
            input: <Form.Item
                name="personFillTitle"
                rules={[
                    {
                        required: requiredByAction.question12,
                        message: 'Title is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "15",
            text: "Email",
            required: requiredByAction.question12,
            input: <Form.Item
                name="personFillEmail"
                rules={[
                    {
                        required: requiredByAction.question12,
                        message: 'Email is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "16",
            text: "Phone Number",
            required: false,
            input: <Form.Item
                name="personFillPhoneNo"
            >
                <Input />
            </Form.Item>
        },
        {
            type: "section",
            text: "B. ESG Practices & Management"
        },
        {
            no: "17",
            text: <b>B-1. Is your organisation a signatory of the United Nations Global Compact (UNGC)?</b>,
            link: "https://unglobalcompact.org/what-is-gc/participants",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="ungcchk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            no: "18",
            text: <b>B-2. Is your organisation a member of the Responsible Business Alliance (RBA)?</b>,
            link: "https://www.responsiblebusiness.org/about/members/",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="rbachk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            no: "19",
            text: <b>B-3. Does your organisation have policies and/or procedures in place to identify, assess and manage the actual and/or potential negative impacts on the following topics?</b>,
            guide: "Mark only one oval per row",
            input:
                <div className="tbl-answer">
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title"></div>
                        <div className="tbl-answer-row-item">No formal policy and/or procedure in place</div>
                        <div className="tbl-answer-row-item">Yes, some formal policies and/or procedures in place</div>
                        <div className="tbl-answer-row-item">Yes, full management system and targets in place</div>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Air & noise pollution</div>
                        <Form.Item
                            name="airNoiseChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Energy & climate change</div>
                        <Form.Item
                            name="energyClimateChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Water & wastwater</div>
                        <Form.Item
                            name="waterWasteChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Waste</div>
                        <Form.Item
                            name="wasteChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Use of other natural resources (e.g. paper, mineral, metals)</div>
                        <Form.Item
                            name="useChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Chemicals and hazardous substances</div>
                        <Form.Item
                            name="chemicalChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Occupational & workplace health and safety</div>
                        <Form.Item
                            name="occupationChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Forced labor, child labor & young workers</div>
                        <Form.Item
                            name="forcedChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Working hours & compensation</div>
                        <Form.Item
                            name="workingHoursChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Non-discrimination</div>
                        <Form.Item
                            name="nonDiscChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Freedom of association & collective bargaining</div>
                        <Form.Item
                            name="freedomChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Human rights</div>
                        <Form.Item
                            name="humanRightChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Fraud & Anti-corruption</div>
                        <Form.Item
                            name="fraudChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Data privacy and cybersecurity</div>
                        <Form.Item
                            name="dataPrivacyChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Supplier management</div>
                        <Form.Item
                            name="supplierChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group className='i-x-left i-y-center'>
                                <div className="tbl-answer-row-item"><Radio value="No"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Some"></Radio></div>
                                <div className="tbl-answer-row-item"><Radio value="Full"></Radio></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>
        },
        {
            no: "20",
            text: <b>B-4. Has your organisation obtained any external certifications? (Check all that apply)</b>,
            guide: "Check all that apply.",
            input: <>
                <Form.Item name="iSO14001Chk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>ISO 14001 Environmental Management System</Checkbox>
                </Form.Item>
                <Form.Item name="iSO50001Chk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>ISO 50001 Energy Management System</Checkbox>
                </Form.Item>
                <Form.Item name="iSO45001Chk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>ISO 45001 Occupational Health & Safety Management System</Checkbox>
                </Form.Item>
                <Form.Item name="iSO14064Chk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>ISO 14064 GHG Quantification & Reporting</Checkbox>
                </Form.Item>
                <Form.Item name="iSO27001Chk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>ISO 27001 Information Security Management System</Checkbox>
                </Form.Item>
                <Form.Item name="iSO22301Chk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>ISO 22301 Business Continuity Management System</Checkbox>
                </Form.Item>
                <Form.Item name="sA8000Chk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>SA 8000 Social Accountability</Checkbox>
                </Form.Item>
                <Form.Item name="bCropChk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>B-Corp Certification</Checkbox>
                </Form.Item>
                <Form.Item name="tAFEPChk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>Singapore Tripartite Alliance for Fair and Progressive Employment Practices (TAFEP)</Checkbox>
                </Form.Item>
                <Form.Item name="bizChk" valuePropName="checked" style={{ marginBottom: "5px" }}>
                    <Checkbox>Singapore BizSAFE</Checkbox>
                </Form.Item>
                <div className="i-x-left i-y-center">
                    <Form.Item name="externalOtherChk" valuePropName="checked">
                        <Checkbox>Other:</Checkbox>
                    </Form.Item>
                    <Form.Item name="externalOtherRmk" style={{ width: "calc(100% - 114px)" }}>
                        <Input />
                    </Form.Item>
                </div>
            </>
        },
        {
            no: "21",
            text: <b>B-5. Does your organisation have a greenhouse gas (GHG) emissions reduction target?</b>,
            guide: "Mark only one oval",
            input: <>
                <Form.Item
                    name="gHGChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group
                        onChange={event => {
                            setRequiredByAction(prev => ({
                                ...prev,
                                question21: event.target.value
                            }))
                        }}
                    >
                        <Space direction="vertical">
                            <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 22</span></Radio>
                            <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 24</span></Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "22",
            text: "a. What is your GHG emissions reduction target(s)?",
            required: requiredByAction.question21,
            input: <Form.Item
                name="gHGRmk"
                rules={[
                    {
                        required: requiredByAction.question21,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input.TextArea showCount maxLength={250} />
            </Form.Item>
        },
        {
            no: "23",
            text: "b. Is the target validated by the Science-Based Targets Initiative (SBTi)?",
            link: "https://sciencebasedtargets.org/companies-taking-action#dashboard",
            guide: "Mark only one oval",
            required: requiredByAction.question21,
            input: <>
                <Form.Item
                    name="sbtiChk"
                    rules={[{
                        required: requiredByAction.question21,
                        message: 'Please mark an oval!'
                    }]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "24",
            text: <b>B-6. Does your organisation have any other climate-related targets, e.g. renewable energy target, energy efficiency target?</b>,
            guide: "Mark only one oval",
            input: <>
                <Form.Item
                    name="climateChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group
                        onChange={event => {
                            setRequiredByAction(prev => ({
                                ...prev,
                                question24: event.target.value
                            }))
                        }}
                    >
                        <Space direction="vertical">
                            <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 25</span></Radio>
                            <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 26</span></Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "25",
            text: "What is your target(s)?",
            required: requiredByAction.question24,
            input: <Form.Item
                name="targetRmk"
                rules={[
                    {
                        required: requiredByAction.question24,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "26",
            text: <b>B-7. Does your organisation publicly disclose its annual GHG emissions?</b>,
            guide: "Mark only one oval",
            input: <>
                <Form.Item
                    name="gHGPubliclyChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group
                        onChange={event => {
                            setRequiredByAction(prev => ({
                                ...prev,
                                question26: event.target.value
                            }))
                        }}
                    >
                        <Space direction="vertical">
                            <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 27</span></Radio>
                            <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 28</span></Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "27",
            text: "Please provide the URL link",
            required: requiredByAction.question26,
            input: <Form.Item
                name="url"
                rules={[
                    {
                        required: requiredByAction.question26,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "28",
            text: <b>B-8. Has your organisation received any regulatory violations or fines pertaining to non-compliance with laws and regulations in the last 2 years?</b>,
            guide: "Mark only one oval",
            input: <>
                <Form.Item
                    name="regulatoryChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group
                        onChange={event => {
                            setRequiredByAction(prev => ({
                                ...prev,
                                question28: event.target.value
                            }))
                        }}
                    >
                        <Space direction="vertical">
                            <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 29</span></Radio>
                            <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 30</span></Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "29",
            text: "Please provide details",
            required: requiredByAction.question28,
            input: <Form.Item
                name="regulatoryRmk"
                rules={[
                    {
                        required: requiredByAction.question28,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "30",
            text: <><b>B-9. Please confirm that you have read, understood and agreed to adhere to StarHub's</b> <Link to="https://tinyurl.com/supplier-code-of-conduct" target="_blank" rel="noopener noreferrer">Supplier Code of Conduct</Link></>,
            info: <p style={{ marginTop: "5px" }}>All StarHub suppliers must adhere to our Code of Conduct. Where local laws
                or national regulations are more stringent in the location that the business
                operates in, suppliers shall meet the highest requirement.</p>,
            guide: "Mark only one oval",
            input: <>
                <Form.Item
                    name="supplierCodeOfConductChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "31",
            text: "Does your company have a Code of Conduct or policy statement covering the same aspects as StarHub Supplier Code of Conduct?",
            guide: "Mark only one oval",
            input: <>
                <Form.Item
                    name="compareCodeOfConductChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </>
        },
        {
            no: "32",
            text: "Please explain what your company have in place to meet the requirements as specified in StarHub's Supplier Code of Conduct?",
            input: <Form.Item
                name="codeOfConductRmk"
                rules={[
                    {
                        required: true,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },










    ]
    return (
        <>
            <Form
                form={form}
                name="application-form"
                size="middle"
                layout="vertical"
                requiredMark={'optional'}
                scrollToFirstError
                disabled={previewOnly}
            >
                <FormSection title="Sustainability Self-Assessment">
                    <div className="questionnaire">
                        <div className="questionnaire-title">
                            StarHub's Supplier Self-Assessment Questionnaire (SAQ)
                        </div>
                        <div className="questionnaire-content">
                            <p>Dear Suppliers,</p>
                            <p>
                                StarHub Ltd is committed to conducting our business in a sustainable and
                                environmentally responsible manner. We seek to ensure that our company and suppliers
                                operate in accordance with the requirements outlined in our <Link to="https://tinyurl.com/supplier-code-of-conduct" target="_blank" rel="noopener noreferrer">Supplier Code of Conduct</Link>.
                            </p>
                            <p>
                                This Supplier Self-Assessment Questionnaire (SAQ) helps us to better understand how
                                your company manages its impact on the environment, society, and economy. It is the
                                first step in establishing a collaborative dialogue between you and StarHub. Please
                                complete the questionnaire candidly, to the best of your knowledge.
                            </p>
                            <p>
                                If you have any questions regarding our Code of Conduct or the questionnaire, please do
                                not hesitate to contact us.
                            </p>
                            <p>
                                Kind Regards <br />
                                StarHub Procurement<br />
                                <a href="mailto:procurement@starhub.com" target="_blank" rel="noopener noreferrer">procurement@starhub.com</a>
                            </p>
                        </div>
                    </div>
                    <Questions items={SustainabilitySelfAssessment} />
                </FormSection>
            </Form>
            <style>{`
                .questionnaire{
                    background-color: #f5f5f5;
                    border-radius: 6px;
                    padding: 20px 25px 5px;
                    margin-top: 10px;
                    margin-right: 25px;
                    margin-bottom: 20px;
                }
                .questionnaire-title{
                    font-size: 25px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    font-weight: bold;
                }
            `}</style>
        </>
    )
}

export default Assessment