import React, { useEffect, useState } from 'react'
import { Alert, Button, Checkbox, Drawer, Form, Input, message, notification, Popconfirm, Tag } from 'antd'
import {
    PlusOutlined,
    EditOutlined,
    SaveOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import Container from '../../../components/Container'
import Main from '../../../components/Main'
import PageHeader from '../../../components/PageHeader'
import AgoraTable from '../../../components/Table';
import { useAuthUser } from 'react-auth-kit';
import axios from 'axios';

function AccGroup() {
    const auth = useAuthUser()
    const [accGroupForm] = Form.useForm();
    const [state, setState] = useState({
        gvAccGroupLoading: false,
        gvAccGroup: [],
        formOpen: false,
        formType: "Add",
        btnSubmitLoading: false,
        btnSubmitResStatus: "",
        btnSubmitResMsg: "",
        btnDeleteLoading: false,
    })
    const handleSetState = (newState) => {
        setState(prevState => ({
            ...prevState,
            ...newState,
        }))
    }
    useEffect(() => {
        getAccGroupPageLoad()
        // eslint-disable-next-line
    }, [])
    const getAccGroupPageLoad = () => {
        handleSetState({ gvAccGroupLoading: true })
        axios.get(`/AccountGroup/PageLoad`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    gvAccGroupLoading: false,
                    gvAccGroup: resData.Data.gvAccountGroup
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }
    const handleOnFinishAccGroup = (formData) => {
        handleSetState({ btnSubmitLoading: true })
        if (state.formType === "Add") {
            axios.post(`/AccountGroup/Create`, {
                accGroupCode: formData.accGroupCode,
                accGroupDesc: formData.accGroupDesc,
                accGroupDefault: formData.accGroupDefault || false,
                createdBy: auth().userId,
            }).then(res => {
                let resData = res.data
                if (resData.Status === "True") {
                    handleSetState({
                        formOpen: false,
                        btnSubmitLoading: false
                    });
                    getAccGroupPageLoad();
                    notification.success({
                        message: "Successful",
                        description: resData.Message
                    });
                } else {
                    handleSetState({
                        btnSubmitLoading: false,
                        btnSubmitResStatus: resData.Status,
                        btnSubmitResMsg: resData.Message,
                    });
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            })
        } else if (state.formType === "Edit") {
            axios.post(`/AccountGroup/Update`, {
                accGroupId: formData.accGroupId,
                accGroupCode: formData.accGroupCode,
                accGroupDesc: formData.accGroupDesc,
                accGroupDefault: formData.accGroupDefault || false,
                modifiedBy: auth().userId,
            }).then(res => {
                let resData = res.data
                if (resData.Status === "True") {
                    handleSetState({
                        formOpen: false,
                        btnSubmitLoading: false
                    });
                    getAccGroupPageLoad();
                    notification.success({
                        message: "Successful",
                        description: resData.Message
                    });
                } else {
                    handleSetState({
                        btnSubmitLoading: false,
                        btnSubmitResStatus: resData.Status,
                        btnSubmitResMsg: resData.Message,
                    });
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            })
        }

    }
    const handleOnDeleteAccGroup = () => {
        handleSetState({ btnDeleteLoading: true })
        axios.post(`/AccountGroup/Delete`, {
            accGroupId: accGroupForm.getFieldValue("accGroupId"),
            modifiedBy: auth().userId,
        }).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    formOpen: false,
                    btnDeleteLoading: false
                });
                getAccGroupPageLoad();
                notification.success({
                    message: "Successful",
                    description: resData.Message
                });
            } else {
                handleSetState({
                    btnDeleteLoading: false,
                    btnSubmitResStatus: resData.Status,
                    btnSubmitResMsg: resData.Message,
                });
            }
        }).catch(error => {
            console.log(error);
            message.error(error.message);
        })

    }

    const isAllowToManage = () => {
        if (auth().role === "Admin") {
            return true
        }
        return false;
    }

    return (
        <Main listBreadcrumb={[
            { key: "1", title: "Maintenance" },
            { key: "2", title: "Library" },
            { key: "3", title: "Account Group" },
        ]}>
            <Container>
                <PageHeader title="Account Group" subtitle="List of account group details.">
                    {isAllowToManage() &&
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                accGroupForm.resetFields();
                                handleSetState({
                                    formOpen: true,
                                    formType: "Add",
                                })
                            }}
                        >
                            New Account Group
                        </Button>
                    }
                </PageHeader>
                <AgoraTable
                    loading={state.gvAccGroupLoading}
                    dataSource={state.gvAccGroup}
                    rowKey="accGroupId"
                    columns={[
                        {
                            title: 'Account Group Code',
                            dataIndex: 'accGroupCode',
                            key: 'accGroupCode',
                            dataType: "text",
                            render: (text, record) => <div className="i-x-space-between">
                                <div className='i-x-left i-y-center'>
                                    {text || "-"}
                                    {record.accGroupDefault && <Tag color="blue" style={{ marginLeft: "10px" }}>Default</Tag>}
                                </div>
                                <div className="i-x-right">
                                    {isAllowToManage() &&
                                        <Button className="table-btn edit" size="small" style={{ marginRight: "10px" }}
                                            onClick={() => {
                                                accGroupForm.setFieldsValue({
                                                    accGroupId: record.accGroupId,
                                                    accGroupCode: record.accGroupCode,
                                                    accGroupDesc: record.accGroupDesc,
                                                    accGroupDefault: record.accGroupDefault,
                                                });
                                                handleSetState({
                                                    formOpen: true,
                                                    formType: "Edit",
                                                })
                                            }}
                                        ><EditOutlined /> Edit</Button>
                                    }
                                </div>
                            </div>,
                        },
                        {
                            title: 'Description',
                            dataIndex: 'accGroupDesc',
                            key: 'accGroupDesc',
                            dataType: "text",
                            render: (text) => text || "-",
                        },
                    ]}
                />
            </Container>
            <Drawer title={`${state.formType} Account Group`}
                width={window.innerWidth > 500 ? 500 : window.innerWidth - 80}
                open={state.formOpen}
                extra={
                    <Button type="primary"
                        loading={state.btnSubmitLoading}
                        onClick={() => accGroupForm.submit()}
                        icon={state.formType === "Add" ? <PlusOutlined /> : <SaveOutlined />}
                    >
                        {state.formType === "Add" ? "Add" : "Update"}
                    </Button>
                }
                onClose={() => {
                    accGroupForm.resetFields()
                    handleSetState({
                        formType: "Add",
                        formOpen: false,
                        btnSubmitResStatus: "",
                        btnSubmitResMsg: "",
                    })
                }}
            >
                <div className="i-y-100 i-y-space-between">
                    <Form
                        form={accGroupForm}
                        name="acc-group-form"
                        layout="vertical"
                        size="middle"
                        requiredMark={false}
                        onFinish={handleOnFinishAccGroup}
                    >
                        {state.btnSubmitResStatus !== ""
                            && <Alert type="error" showIcon style={{ margin: "0px 25px 20px 0px" }}
                                message={state.btnSubmitResMsg}
                            />
                        }
                        <Form.Item hidden={true} name="accGroupId" ></Form.Item>
                        <Form.Item
                            label="Account Group Code"
                            name="accGroupCode"
                            tooltip="This is a required field"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter account group code!',
                                },
                            ]}
                        >
                            <Input placeholder='e.g., SH00' />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="accGroupDesc"
                            tooltip="This is a required field"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter account group description!',
                                },
                            ]}
                        >
                            <Input placeholder='e.g., StarHub Corp Purch' />
                        </Form.Item>
                        <Form.Item name="accGroupDefault" valuePropName="checked" noStyle>
                            <Checkbox>Set as Default</Checkbox>
                        </Form.Item>
                    </Form>
                    {state.formType === "Edit"
                        ? <div>
                            <Popconfirm
                                title="Delete account group"
                                description="Are you sure to delete this account group?"
                                onConfirm={handleOnDeleteAccGroup}
                                okText="Yes"
                                okType="danger"
                            >
                                <Button type="primary" danger block loading={state.btnDeleteLoading}
                                    icon={<DeleteOutlined />}
                                >Delete Account Group</Button>
                            </Popconfirm>
                        </div>
                        : ""
                    }
                </div>
            </Drawer>
        </Main>
    )
}

export default AccGroup