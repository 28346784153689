import React from 'react'
import './FormSection.css'

const FormSection = ({ title, children, type }) => {
    return (
        <>
            <div className="form-section">
                {title && <div className={`form-header ${type}`}>
                    <div className="form-header-title">{title}</div>
                </div>}
                <div className="form-body">
                    {children}
                </div>
            </div>
        </>
    )
}

export default FormSection