import React, { useEffect, useState } from 'react'
import axios from 'axios';
import dayjs from 'dayjs';
import { Alert, Button, Form, Input, message, notification, Popconfirm, Upload } from 'antd'
import {
    InboxOutlined,
    DownloadOutlined,
    DeleteOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import FormSection from './FormSection';
import AgoraTable from './Table';
import AgoraSelect from './Select';

import './Attachment.css';

function Attachment({ appId, title, listAttachment, setListAttachment, previewOnly, extraInfo }) {
    const [attachmentForm] = Form.useForm();
    const [tblAttachmentLoading, setTblAttachmentLoading] = useState(false)
    useEffect(() => {
        getDocType()
        // eslint-disable-next-line
    }, [])
    const [selectedDocType, setSelectedDocType] = useState("")
    const [ddlDocType, setDdlDocType] = useState([])
    const getDocType = () => {
        axios.get(`Attachment/GetDocumentType`).then(res => {
            setTblAttachmentLoading(false)
            let resData = res.data
            if (resData.Status === "True") {
                setDdlDocType(resData.Data)
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            setTblAttachmentLoading(false)
            message.error(error.Message)
        })
    }

    const getAttachment = () => {
        setTblAttachmentLoading(true)
        axios.get(`Attachment/PageLoad/${appId}`).then(res => {
            setTblAttachmentLoading(false)
            let resData = res.data
            if (resData.Status === "True") {
                setListAttachment(resData.Data)
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            setTblAttachmentLoading(false)
            message.error(error.Message)
        })
    }
    const [uploadAttchBtnLoading, setUploadAttchBtnLoading] = useState(false)
    const handleUploadAttachment = (formData) => {
        setUploadAttchBtnLoading(true)
        var bodyFormData = new FormData();
        bodyFormData.append('AppId', appId);
        formData.dragger.forEach(data => {
            bodyFormData.append('files', data.originFileObj);
        });
        bodyFormData.append('DocType', formData.documentType);
        bodyFormData.append('DocRemark', formData.documentRemark);

        axios.post(`Attachment/Upload`, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
            setUploadAttchBtnLoading(false)
            let resData = res.data
            if (resData.Status === "True") {
                console.log(resData)
                getAttachment();
                setSelectedDocType("")
                attachmentForm.resetFields();
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            setUploadAttchBtnLoading(false)
            message.error(error.Message)
        })
    }
    const handleDownloadAttachment = (attId, fileName) => {
        setTblAttachmentLoading(true)
        axios.get(`Attachment/Download/${attId}`, {
            responseType: 'blob',
        }).then(response => {
            setTblAttachmentLoading(false)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName || `file.png`);  // Replace with your file name and extension
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            setTblAttachmentLoading(false)
            console.log(error)
            message.error(error.Message)
        })
    }

    const handleDeleteAttachment = (attId) => {
        setTblAttachmentLoading(true)
        axios.post(`Attachment/Delete`, {
            AppId: appId,
            AttId: attId,
        }).then(res => {
            setTblAttachmentLoading(false)
            let resData = res.data
            if (resData.Status === "True") {
                console.log(resData)
                notification.success({
                    message: resData.Message
                })
                getAttachment();
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            setTblAttachmentLoading(false)
            console.log(error)
            message.error(error.Message)
        })
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    return (
        <div>

            <FormSection title={title || "Attachment"}>
                {previewOnly !== true && <>
                    <Form
                        form={attachmentForm}
                        name="attachment-upload"
                        size="middle"
                        layout="vertical"
                        requiredMark={false}
                        onFinish={handleUploadAttachment}
                        scrollToFirstError
                    >
                        <div className="attachment-upload">
                            {extraInfo &&
                                <Alert showIcon type="warning" style={{ fontSize: "13px", marginRight: "20px", padding: "5px 10px", marginBottom: "12px" }}
                                    message={extraInfo}
                                />
                            }
                            <Alert showIcon type="info" style={{ fontSize: "13px", marginRight: "20px", padding: "5px 10px", marginBottom: "12px" }}
                                message="You can attach up to a total size of 25 MB per file. Permitted file types are .doc .docx .xls .xlsx .pdf .zip .txt .tif .jpg. And Maximum file name length of 100 characters"
                            />
                            <Form.Item
                                label="Document Type"
                                name="documentType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Document type is required!',
                                    },
                                ]}
                            >
                                <AgoraSelect
                                    allowClear
                                    placeholder="Please select document type"
                                    options={ddlDocType}
                                    onChange={(value) => {
                                        setSelectedDocType(value)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Document Remark"
                                name="documentRemark"
                                hidden={selectedDocType.includes("OTHERS") ? false : true}
                                rules={[
                                    {
                                        required: selectedDocType.includes("OTHERS") ? true : false,
                                        message: 'Document remark is required!',
                                    },
                                ]}
                            >
                                <Input placeholder="Please type document type here" />
                            </Form.Item>
                            <Form.Item label="Upload Document">
                                <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Document is required!',
                                        },
                                    ]}
                                >
                                    <Upload.Dragger
                                        name="files"
                                        multiple
                                        headers={{ "Content-Type": "multipart/form-data" }}
                                        beforeUpload={file => {
                                            if (file.name.length > 100) {
                                                notification.error({
                                                    message: "File name too long!",
                                                    description: `${file.name} got ${file.name.length} characters.`
                                                })
                                            } else if (file.type !== 'application/msword' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
                                                file.type !== 'application/vnd.ms-excel' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
                                                file.type !== 'application/pdf' && file.type !== 'application/zip' &&
                                                file.type !== 'application/x-zip-compressed' &&
                                                file.type !== 'image/tiff' && file.type !== 'image/jpeg' &&
                                                file.type !== 'text/plain') {
                                                notification.error({
                                                    message: "Invalid file type!",
                                                    description: `${file.name} is "${file.type}" file type. "${file.type}" not allow to be uploaded.`
                                                })
                                            } else if (file.size > 26214400) {
                                                notification.error({
                                                    message: "File size more than 25 MB!",
                                                    description: `${file.name} size is ${(file.size / 1048576).toFixed(2)} MB.`
                                                })
                                            } else {
                                                return false
                                            }
                                            return Upload.LIST_IGNORE;
                                        }}

                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Form.Item>
                            <div className="i-x-right" style={{ marginRight: "20px" }}>
                                <Button type="primary" size="small" icon={<UploadOutlined />}
                                    loading={uploadAttchBtnLoading}
                                    htmlType="submit"
                                >Upload</Button>
                            </div>
                        </div>
                    </Form>
                </>}
            </FormSection>
            <AgoraTable
                loading={tblAttachmentLoading}
                rowKey="AttId"
                dataSource={listAttachment}
                columns={[
                    {
                        title: 'Document Name',
                        dataIndex: 'FileName',
                        key: 'FileName',
                        dataType: "text",
                        render: (text, record) => <div className="i-x-space-between">
                            <div>{text || "-"}</div>
                            <div className="i-x-right">
                                <Button className="table-btn primary" size="small" danger block loading={false} style={{ marginRight: "5px" }}
                                    onClick={() => handleDownloadAttachment(record.AttId, record.FileName)}
                                ><DownloadOutlined /> Download</Button>
                                {previewOnly !== true && <>
                                    <Popconfirm
                                        title="Delete attachment"
                                        description="Are you sure to delete this attachment?"
                                        onConfirm={() => handleDeleteAttachment(record.AttId)}
                                        okText="Yes"
                                        okType="danger"
                                    >
                                        <Button className="table-btn delete" size="small" danger block loading={false}><DeleteOutlined /> Delete</Button>
                                    </Popconfirm>
                                </>
                                }
                            </div>
                        </div>,
                    },
                    {
                        title: 'Document Type',
                        dataIndex: 'DocDesc',
                        key: 'DocDesc',
                        dataType: "text",
                        render: (text, record) => {
                            return text.toUpperCase().includes("OTHERS") ? record.DocRemark ? `${record.DocRemark} (Others)` : "Others"
                                : text || "-"
                        }
                    },
                    {
                        title: 'Upload Date',
                        dataIndex: 'CreatedDate',
                        key: 'CreatedDate',
                        dataType: "date",
                        render: (text) => dayjs(text, { utc: true }).format("DD/MM/YYYY - hh:mm:ss A") || "-",
                    },
                ]}
            />
        </div >
    )
}

export default Attachment