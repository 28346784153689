import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    EyeOutlined,
    DownloadOutlined,
    DownOutlined,
} from '@ant-design/icons';
import { Badge, Button, Dropdown, message } from 'antd';
import { Link } from 'react-router-dom';
import Main from '../../components/Main';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import Table from '../../components/Table';
import { useAuthUser } from 'react-auth-kit'

function VendorList() {
    const auth = useAuthUser()
    const [state, setState] = useState({
        gvVendorLoading: false,
        gvVendor: [],
        exportBtnLoading: false,
        gvVendorSelected: [],
    })
    const handleSetState = (newState) => {
        setState(prevState => ({
            ...prevState,
            ...newState,
        }))
    }
    useEffect(() => {
        getVendorList()
        // eslint-disable-next-line
    }, [])
    const getVendorList = () => {
        handleSetState({ gvVendorLoading: true })
        axios.get(`/VendorListing/PageLoad`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    gvVendorLoading: false,
                    gvVendor: resData.Data.gvVendorListing
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }
    const exportReport = (type) => {
        let selectedVendorAppId = state.gvVendorSelected.map(data => data.appID)
        console.log(selectedVendorAppId)

        let apiExportUrl = ""
        let fileName = ""
        if (type === "SustainabilityForm") {
            apiExportUrl = "/ExportExcel/VendorSustainabilitySelfAssessment"
            fileName = "Vendor Sustainability Self-Assessment Report.csv"
        } else if (type === "RegAuditLog") {
            apiExportUrl = "/ExportExcel/AuditLog"
            fileName = "Audit Log.xlsx"
        } else { // "VendorInfo"
            apiExportUrl = "/ExportCSV/VendorInformationListingCSV"
            fileName = "Vendor Information.csv"
        }

        handleSetState({ gvVendorLoading: true, exportBtnLoading: true })
        axios.post(apiExportUrl, {
            multiAppID: selectedVendorAppId
        }, {
            responseType: 'blob',
        }).then(res => {
            handleSetState({ gvVendorLoading: false, exportBtnLoading: false })
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);  // Replace with your file name and extension
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            handleSetState({ gvVendorLoading: false, exportBtnLoading: false })
            console.log(error)
            message.error(error.message)
        })
    }

    return (
        <>
            <Main listBreadcrumb={[
                { key: "1", title: "Report" },
                { key: "2", title: "Vendor Listing" }
            ]}>
                <Container>
                    <PageHeader title="Vendor Listing" subtitle="Search and select vendor to extract report by click export button.">
                        <Dropdown
                            disabled={state.gvVendorSelected.length > 0 ? false : true}
                            placement="bottomRight"
                            menu={{
                                style: { padding: "2px 0px" },
                                items: [
                                    {
                                        key: '1',
                                        icon: <DownloadOutlined />,
                                        label: <div>Vendor Information</div>,
                                        style: {
                                            borderBottom: "1px solid #eeeeee",
                                            borderRadius: "0px",
                                            padding: "5px 15px"
                                        },
                                        onClick: () => {
                                            exportReport("VendorInfo")
                                        }
                                    },
                                    {
                                        key: '2',
                                        icon: <DownloadOutlined />,
                                        label: <div>Sustainability Self-Assessment Form</div>,
                                        style: {
                                            borderBottom: "1px solid #eeeeee",
                                            borderRadius: "0px",
                                            padding: "5px 15px"
                                        },
                                        onClick: () => {
                                            exportReport("SustainabilityForm")
                                        }
                                    },
                                    {
                                        key: '3',
                                        icon: <DownloadOutlined />,
                                        label: <div>Registration Audit Log</div>,
                                        style: {
                                            borderRadius: "0px",
                                            padding: "5px 15px"
                                        },
                                        onClick: () => {
                                            exportReport("RegAuditLog")
                                        }
                                    },
                                ]
                            }}
                        >
                             {auth().role === 'Requester' 
                             ? (<></>) 
                             : (<Button
                                    type="primary"
                                    disabled={auth().role === 'Requester'}
                                    loading={state.exportBtnLoading}
                                >
                                    Export <DownOutlined />
                                </Button>
                                )}
                        </Dropdown>
                    </PageHeader>
                    <Table
                        key="vendorID"
                        loading={state.gvVendorLoading}
                        dataSource={state.gvVendor}
                        rowSelection={
                            auth().role === 'Requester'
                                ? undefined
                                : {
                                      type: 'checkbox',
                                      onChange: (selectedRowKeys, selectedRows) => {
                                          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                                          handleSetState({ gvVendorSelected: selectedRows });
                                      },
                                      getCheckboxProps: (record) => ({
                                          // disabled: record.vendorAppStatus === 'New Invitation',
                                          // Column configuration not to be checked
                                          // vendorID: record.vendorID,
                                      }),
                                  }
                        }
                        columns={[
                            {
                                title: 'Vendor Name',
                                dataIndex: 'vendorName',
                                key: 'vendorName',
                                dataType: "text",
                                render: (text, record) => <div className="i-x-space-between">
                                    <div>{text || "-"}</div>
                                    <div>
                                        <Link to={`./${record.vendorID}`}><Button className="table-btn default" size="small"><EyeOutlined style={{ fontSize: "13px" }} /> View</Button>  </Link>
                                    </div>
                                </div>
                            },
                            {
                                title: 'Vendor Email',
                                dataIndex: 'vendorEmail',
                                key: 'vendorEmail',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Vendor From',
                                dataIndex: 'vendorFrom',
                                key: 'vendorFrom',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Vendor ID (BRN)',
                                dataIndex: 'vendorCompBRN',
                                key: 'vendorCompBRN',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Vendor Account',
                                dataIndex: 'vendorAccount',
                                key: 'vendorAccount',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Vendor Status',
                                dataIndex: 'vendorAppStatus',
                                key: 'vendorAppStatus',
                                dataType: "text",
                                render: (text, record) => <div className="i-x-space-between i-y-center">
                                    <div>
                                        <Badge
                                            text={text}
                                            status={text.includes("Pending") ? "processing"
                                                : text === "New Invitation" ? "warning"
                                                    : text === "Acknowledged" ? "success"
                                                        : "error"
                                            }
                                        />
                                    </div>
                                </div>
                            },
                        ]}
                    />
                </Container>
            </Main>
        </>
    )
}

export default VendorList
