import React from 'react'
import { Link } from 'react-router-dom';
import {
    HomeOutlined,
    IdcardOutlined,
    LinkOutlined,
    UserOutlined,
    DatabaseOutlined,
    SwapRightOutlined,
    ContainerOutlined,
} from '@ant-design/icons';

const SideMenuItems = [
    {
        key: 'home',
        icon: <HomeOutlined />,
        label: <Link to="/home">Home</Link>,
    },
    {
        key: 'mytask',
        type: "group",
        label: "MY TASK",
        children: [
            {
                key: 'mytask_createregistrationlink',
                icon: <LinkOutlined />,
                label: <Link to="/mytask/createregistrationlink">Create Registration Link</Link>,
            },
            {
                key: 'mytask_listapproval',
                icon: <ContainerOutlined />,
                label: <Link to="/mytask/listapproval">List Approval</Link>,
            },
        ]
    },
    {
        key: 'report',
        type: "group",
        label: "REPORT",
        children: [
            {
                key: 'report_vendorlisting',
                icon: <IdcardOutlined />,
                label: <Link to="/report/vendorlisting">Vendor Listing</Link>,
            },
        ]
    },
    {
        key: 'maintenance',
        type: "group",
        label: "MAINTENANCE",
        children: [
            {
                key: 'maintenance_user',
                icon: <UserOutlined />,
                label: <Link to="/maintenance/user">User Management</Link>,
            },
            {
                key: 'adminpanel',
                icon: <DatabaseOutlined />,
                label: "Admin Panel",
                children: [
                    {
                        key: 'maintenance_adminpanel_accgroup',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/accgroup">Account Group</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_currency',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/currency">Currency</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_documenttype',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/documenttype">Document Type</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_language',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/language">Language</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_nametitle',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/nametitle">Name Title</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_paymentterms',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/paymentterms">Payment Terms</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_purchaseorg',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/purchaseorg">Purchasing Organization</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_country',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/country">Country</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_state',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/state">Region/State</Link>,
                    },
                    {
                        key: 'maintenance_adminpanel_timezone',
                        icon: <SwapRightOutlined />,
                        label: <Link to="/maintenance/adminpanel/timezone">Time Zone</Link>,
                    },
                ]
            },
        ]
    },
]

export default SideMenuItems