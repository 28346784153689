import React, { useEffect, useState } from 'react';
import {
    PlusOutlined,
    EditOutlined,
    SaveOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { Alert, Badge, Button, Drawer, Form, Input, message, notification, Popconfirm, Radio } from 'antd';
import { useAuthUser } from 'react-auth-kit'
import Main from '../../components/Main';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import Table from '../../components/Table';
import axios from 'axios';
import AgoraSelect from '../../components/Select';

function Users() {
    const auth = useAuthUser()
    const [userForm] = Form.useForm();
    const [state, setState] = useState({
        ddlUserRole: [],
        gvUserLoading: false,
        gvUser: [],
        formOpen: false,
        formType: "Add",
        btnSubmitLoading: false,
        btnSubmitResStatus: "",
        btnSubmitResMsg: "",
        btnDeleteLoading: false,
    })
    const handleSetState = (newState) => {
        setState(prevState => ({
            ...prevState,
            ...newState,
        }))
    }
    useEffect(() => {
        getUserPageLoad()
        // eslint-disable-next-line
    }, [])
    const getUserPageLoad = () => {
        handleSetState({ gvUserLoading: true })
        axios.get(`/UserMaintenance/UserMaintenancePageLoad`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    gvUserLoading: false,
                    gvUser: resData.Data.gvUsers,
                    ddlUserRole: resData.Data.ddlRole,
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }
    const handleOnFinishUserForm = (formData) => {
        handleSetState({ btnSubmitLoading: true })
        if (state.formType === "Add") {
            axios.post(`/UserMaintenance/AddUserDetails`, {
                fullName: formData.fullName,
                email: formData.email,
                role: formData.role,
                status: formData.status,
                createdBy: auth().userId,
            }).then(res => {
                let resData = res.data
                if (resData.Status === "True") {
                    handleSetState({
                        formOpen: false,
                        btnSubmitLoading: false
                    });
                    getUserPageLoad();
                    notification.success({
                        message: "Successful",
                        description: resData.Message
                    });
                } else {
                    handleSetState({
                        btnSubmitLoading: false,
                        btnSubmitResStatus: resData.Status,
                        btnSubmitResMsg: resData.Message,
                    });
                }
            }).catch(error => {
                console.log(error)
                message.error(error.message)
            })
        } else if (state.formType === "Edit") {
            axios.post(`/UserMaintenance/UpdateUserDetails`, {
                userId: formData.userId,
                fullName: formData.fullName,
                email: formData.email,
                role: formData.role,
                status: formData.status,
                modifiedBy: auth().userId,
            }).then(res => {
                let resData = res.data
                if (resData.Status === "True") {
                    handleSetState({
                        formOpen: false,
                        btnSubmitLoading: false
                    });
                    getUserPageLoad();
                    notification.success({
                        message: "Successful",
                        description: resData.Message
                    });
                } else {
                    handleSetState({
                        btnSubmitLoading: false,
                        btnSubmitResStatus: resData.Status,
                        btnSubmitResMsg: resData.Message,
                    });
                }
            }).catch(error => {
                console.log(error)
                message.error(error.message)
            })
        }
    }
    const handleOnDeleteUser = () => {
        handleSetState({ btnDeleteLoading: true })
        axios.post(`/UserMaintenance/DeleteUserDetails`, {
            userId: userForm.getFieldValue("userId"),
            modifiedBy: auth().userId,
        }).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                handleSetState({
                    formOpen: false,
                    btnDeleteLoading: false
                });
                getUserPageLoad();
                notification.success({
                    message: "Successful",
                    description: resData.Message
                });
            } else {
                handleSetState({
                    btnDeleteLoading: false,
                    btnSubmitResStatus: resData.Status,
                    btnSubmitResMsg: resData.Message,
                });
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }

    const isAllowToManage = () => {
        if (auth().role === "Admin") {
            return true
        }
        return false;
    }

    return (
        <>
            <Main listBreadcrumb={[
                { key: "1", title: "Maintenance" },
                { key: "2", title: "User Management" }
            ]}>
                <Container>
                    <PageHeader title="User Management" subtitle="List of all registered user.">
                        {isAllowToManage() &&
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    userForm.resetFields();
                                    handleSetState({
                                        formOpen: true,
                                        formType: "Add",
                                    })
                                }}
                            >
                                New User
                            </Button>
                        }
                    </PageHeader>
                    <Table
                        loading={state.gvUserLoading}
                        dataSource={state.gvUser}
                        rowKey="userId"
                        columns={[
                            {
                                title: 'Full Name',
                                dataIndex: 'fullName',
                                key: 'fullName',
                                dataType: "text",
                                render: (text, record) => <div className="i-x-space-between">
                                    <div>{text || "-"}</div>
                                    <div className="i-x-right">
                                        {isAllowToManage() &&
                                            <Button className="table-btn edit" size="small"
                                                onClick={() => {
                                                    userForm.setFieldsValue({
                                                        userId: record.userId,
                                                        fullName: record.fullName,
                                                        email: record.email,
                                                        role: record.role,
                                                        status: record.status,
                                                    });
                                                    handleSetState({
                                                        formOpen: true,
                                                        formType: "Edit",
                                                    })
                                                }}
                                            ><EditOutlined /> Edit</Button>
                                        }
                                    </div>
                                </div>,
                            },
                            {
                                title: 'Role',
                                dataIndex: 'role',
                                key: 'role',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                key: 'email',
                                dataType: "text",
                                render: (text) => text || "-",
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                                dataType: "text",
                                render: (text) => <div className="i-x-space-between i-y-center">
                                    <div>
                                        <Badge
                                            text={text}
                                            status={text === "Inactive" ? "red" : "blue"}
                                        />
                                    </div>
                                </div>
                            },
                        ]}
                    />
                </Container>
                <Drawer title={`${state.formType} User`}
                    width={window.innerWidth > 500 ? 500 : window.innerWidth - 80}
                    open={state.formOpen}
                    extra={
                        <Button type="primary"
                            loading={state.btnSubmitLoading}
                            onClick={() => userForm.submit()}
                            icon={state.formType === "Add" ? <PlusOutlined /> : <SaveOutlined />}
                        >
                            {state.formType === "Add" ? "Add" : "Update"}
                        </Button>
                    }
                    onClose={() => {
                        userForm.resetFields()
                        handleSetState({
                            formType: "Add",
                            formOpen: false,
                            btnSubmitResStatus: "",
                            btnSubmitResMsg: "",
                        })
                    }}
                >
                    <div className="i-y-100 i-y-space-between">
                        <Form
                            form={userForm}
                            name="user-form"
                            layout="vertical"
                            size="middle"
                            requiredMark={false}
                            onFinish={handleOnFinishUserForm}
                        >
                            {state.btnSubmitResStatus !== ""
                                && <Alert type="error" showIcon style={{ margin: "0px 25px 20px 0px" }}
                                    message={state.btnSubmitResMsg}
                                />
                            }
                            <Form.Item hidden={true} name="userId" />
                            <Form.Item
                                label="Full Name"
                                name="fullName"
                                tooltip="This is a required field"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter user full name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                tooltip="This is a required field"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter user email!',
                                    },
                                    {
                                        type: "email",
                                        message: 'Please enter valid email!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Role"
                                name="role"
                                tooltip="This is a required field"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select user role!',
                                    },
                                ]}
                            >
                                <AgoraSelect
                                    placeholder="Please select role"
                                    options={state.ddlUserRole}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="status"
                                hidden={state.formType === "Add"}
                            >
                                <Radio.Group>
                                    <Radio.Button value="Active">Active</Radio.Button>
                                    <Radio.Button value="Inactive">Inactive</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                        {state.formType === "Edit"
                            ? <div>
                                <Alert message="We recommend to change user status to inactive rather then delete the user." type="info" showIcon style={{ marginBottom: "10px", fontSize: "13px" }} />
                                <Popconfirm
                                    title="Delete user"
                                    description="Are you sure to delete this user?"
                                    onConfirm={handleOnDeleteUser}
                                    okText="Yes"
                                    okType="danger"
                                >
                                    <Button type="primary" danger block loading={state.btnDeleteLoading}
                                        icon={<DeleteOutlined />}
                                    >Delete user</Button>
                                </Popconfirm>
                            </div>
                            : ""
                        }
                    </div>
                </Drawer>
            </Main>

        </>
    )
}

export default Users;