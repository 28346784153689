import 'antd/dist/reset.css';
import 'react-phone-number-input/style.css'
import './assets/styles/custom.css';
import './assets/styles/custom-grid.css';

import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider, notification } from 'antd';
import { AuthProvider, RequireAuth } from 'react-auth-kit';
import MainLayout from './layouts/Main';
import NotFound from './pages/NotFound';
import { openRoutes, privateRoutes } from './routes/AppRoutes';
import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}/api`;
// axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('_auth')}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(function (config) {
    const token = Cookies.get('_auth');
    config.headers.Authorization = `Bearer ${token || ""}`;
    return config;
});

notification.config({
    placement: "top",
});

export default function App() {
    return (
        <AuthProvider
            authType={'cookie'}
            authName={'_auth'}
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === "https:"}
        >
            <React.StrictMode>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#4caf50',
                            colorTextBase: "#212529",
                            fontFamily: "Poppins",
                            borderRadius: 4,
                        },
                    }}
                >
                    <Suspense fallback={<div className="container">Loading...</div>}>
                        <Routes>
                            {openRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                            {privateRoutes.map((route, index) => {
                                const { element, menuKey, ...rest } = route;
                                return <Route key={index} {...rest}
                                    element={<RequireAuth loginPath={'/'}>
                                        <MainLayout activeMenu={menuKey}>{element}</MainLayout>
                                    </RequireAuth>}
                                />;
                            })}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Suspense>
                </ConfigProvider>
            </React.StrictMode>
        </AuthProvider>
    )
}
