import React, { useEffect } from 'react'
import {
    HomeOutlined
} from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const Main = (props) => {
    const { listBreadcrumb } = props
    useEffect(() => {
        let listBreadcrumbTotal = listBreadcrumb.length;
        document.title = `Agora VRMS - ` + listBreadcrumb[listBreadcrumbTotal - 1].title || "";
    }, [listBreadcrumb]);
    return (
        <>
            <Breadcrumb style={{ margin: '10px 5px' }}>
                <Breadcrumb.Item >
                    <Link to="/home">
                        <HomeOutlined />
                    </Link>
                </Breadcrumb.Item>
                {listBreadcrumb && listBreadcrumb.map(data =>
                    <Breadcrumb.Item key={data.key}>
                        {data.link
                            ? <Link to={data.link}>
                                {data.icon}
                                <span>{data.title}</span>
                            </Link>
                            : <>
                                {data.icon}
                                <span>{data.title}</span>
                            </>
                        }
                    </Breadcrumb.Item>
                )}
            </Breadcrumb>
            {props.children}
        </>
    )
}

export default Main