import React, { useEffect, useState } from 'react';
import Main from '../components/Main';
import Container from '../components/Container';
import PageHeader from '../components/PageHeader';
import { Button, Form, Input, message, notification } from 'antd';
import FormSection from '../components/FormSection';
import axios from 'axios';

function UserProfile() {
    const [updateProfileForm] = Form.useForm();
    const [changePasswordForm] = Form.useForm();
    const [state, setState] = useState({
        btnUpdateProfileLoading: false,
        btnChangePasswordLoading: false,
    })
    const handleSetState = (newState) => {
        setState(prevState => ({
            ...prevState,
            ...newState,
        }))
    }
    useEffect(() => {
        getUserProfile()
        // eslint-disable-next-line
    }, [])
    const getUserProfile = () => {
        axios.get(`/UserProfile/PageLoad`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                updateProfileForm.setFieldsValue({
                    fullname: resData.Data.fullname,
                    email: resData.Data.email,
                    role: resData.Data.role,
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }
    const updateUserProfile = (formData) => {
        handleSetState({ btnUpdateProfileLoading: true })
        axios.post(`/UserProfile/UpdateProfile`, {
            fullname: formData.fullname,
            email: formData.email,
        }).then(res => {
            let resData = res.data
            handleSetState({ btnUpdateProfileLoading: false })
            if (resData.Status === "True") {
                getUserProfile();
                notification.success({
                    message: "Successful",
                    description: resData.Message,
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            handleSetState({ btnUpdateProfileLoading: false })
            console.log(error)
            message.error(error.message)
        })
    }
    const changePassword = (formData) => {
        handleSetState({ btnChangePasswordLoading: true })
        axios.post(`/UserProfile/ChangePassword`, {
            currentPassword: formData.currentPassword,
            newPassword: formData.newPassword,
        }).then(res => {
            let resData = res.data
            handleSetState({ btnChangePasswordLoading: false })
            if (resData.Status === "True") {
                changePasswordForm.resetFields();
                notification.success({
                    message: "Successful",
                    description: resData.Message,
                })
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            handleSetState({ btnChangePasswordLoading: false })
            console.log(error)
            message.error(error.message)
        })
    }
    return (
        <Main listBreadcrumb={[
            { key: "1", title: "My Profile" }
        ]}>
            <div className="i-x-center">
                <div className="d-65 t-80 m-100">
                    <Container>
                        <PageHeader
                            title="Update Profile"
                            subtitle="Fill in all your details below and click update."
                        >
                            <Button type="primary"
                                loading={state.btnUpdateProfileLoading}
                                onClick={() => updateProfileForm.submit()}
                            >
                                Update
                            </Button>
                            <Button type="default" style={{ marginLeft: "10px" }}
                                onClick={() => getUserProfile()}
                            >
                                Reset
                            </Button>
                        </PageHeader>
                        <FormSection>
                            <Form
                                form={updateProfileForm}
                                name="change-password-form"
                                layout="vertical"
                                size="middle"
                                requiredMark={false}
                                onFinish={updateUserProfile}
                            >
                                <Form.Item
                                    label="Full Name"
                                    name="fullname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your full name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please type your full name here" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                        {
                                            type: "email",
                                            message: 'Please input a valid email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please type your email here" />
                                </Form.Item>
                                <Form.Item
                                    label="Role"
                                    name="role"
                                >
                                    <Input disabled placeholder="Please type here" />
                                </Form.Item>
                            </Form>
                        </FormSection>
                    </Container>
                    <Container>
                        <PageHeader
                            title="Change Password"
                            subtitle="Fill in your current password and new password to change your password."
                        >
                            <Button type="primary"
                                loading={state.btnChangePasswordLoading}
                                onClick={() => changePasswordForm.submit()}
                            >
                                Change
                            </Button>
                        </PageHeader>
                        <FormSection>
                            <Form
                                form={changePasswordForm}
                                name="change-password-form"
                                layout="vertical"
                                size="middle"
                                requiredMark={false}
                                onFinish={changePassword}
                            >
                                <Form.Item
                                    label="Current Password"
                                    name="currentPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your current password!',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Please type your current password here" />
                                </Form.Item>

                                <Form.Item
                                    label="New Password"
                                    name="newPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your new password!',
                                        },
                                        {
                                            message: 'At least 8 characters in length!',
                                            min: 8,
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Please type your new password here" />
                                </Form.Item>
                            </Form>
                        </FormSection>
                    </Container>
                </div>
            </div>
        </Main>
    );
}

export default UserProfile
