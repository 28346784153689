import React from 'react';
import './ContainerSection.css';

const ContainerSection = ({ title, children, type, style, bodyStyle }) => {
    return (
        <>
            <div className="container-section" style={style}>
                {title && <div className={`container-header ${type}`}>
                    <div className="container-header-title">{title}</div>
                </div>}
                <div className="container-body" style={bodyStyle}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default ContainerSection