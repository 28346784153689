import React from 'react'
import { Divider } from 'antd'
import Question from './Question'

import './Questions.css';

function Questions({ items }) {
    return (
        <>
            <div className="questions">
                <div className="questionnaire-required">* Required</div>
                <div className="questionnaire-body">
                    {items.map((data, index) => data.type === "section"
                        ? <Divider key={index} orientation="left" orientationMargin={0}><b>{data.text}</b></Divider>
                        : <Question
                            key={index}
                            no={data.no}
                            text={data.text}
                            info={data.info}
                            link={data.link}
                            guide={data.guide}
                            required={data.required}
                        >
                            {data.input}
                        </Question>
                    )}
                </div>
            </div>
        </>
    )
}

export default Questions