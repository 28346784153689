import React, { useEffect, useState } from 'react';
import { Checkbox, DatePicker, Form, Input, Radio, Space } from "antd";
import { Link } from 'react-router-dom'
import AgoraSelect from '../../../../components/Select';
import FormSection from '../../../../components/FormSection'
import Questions from '../../../../components/Questions'

import './Assessment.css'

const QuestionV1 = ({ ddlData, form, handleOnSubmitForm, handleOnChangeCountry, previewOnly }) => {
    const formData = form.getFieldValue()
    const [countrySelected, setCountrySelected] = useState(false)
    const [requiredByAction, setRequiredByAction] = useState({
        question15: formData.discloseChk === false ? false : true,
        question25: formData.externalCertAchk === false ? false : true,
        question29: formData.certifiedEnvironmentChk === false ? false : true,
        question33: formData.humanRightsChk === false ? false : true,
    })
    useEffect(() => {
        setCountrySelected(formData.country ? true : false)
    }, [formData])
    const SustainabilitySelfAssessment = [
        {
            type: "section",
            text: "Section 1 : Company Information"
        },
        {
            no: "1",
            text: "Name of company",
            info: "(Per your Business Registration)",
            input: <Form.Item
                name="companyName"
                rules={[
                    {
                        required: true,
                        message: 'Name of company is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "2",
            text: "StarHub Vendor ID (BRN)",
            info: "(Same as Business Registration Number)",
            input: <Form.Item
                name="brn"
                rules={[
                    {
                        required: true,
                        message: 'StarHub Vendor ID is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "3",
            text: "Business Registration Number",
            info: "(If your business is registered in Singapore. it is your ACRA UEN. Otherwise, state your BRN per where you are incorporated)",
            input: <Form.Item
                name="acra"
                rules={[
                    {
                        required: true,
                        message: 'Company BRN is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "4",
            text: "Address",
            info: "(Per BRN above)",
            input: <div className="i-x-left i-x-wrap">
                <div className="d-50 m-100">
                    <Form.Item
                        name="address1"
                        rules={[
                            {
                                required: true,
                                message: 'Street Name is required!',
                            },
                        ]}
                    >
                        <Input placeholder="Street Name" />
                    </Form.Item>
                    <Form.Item
                        name="address2"
                        rules={[
                            {
                                required: true,
                                message: 'Unit No is required!',
                            },
                        ]}
                    >
                        <Input placeholder="Unit No" />
                    </Form.Item>
                    <Form.Item
                        name="address3"
                        rules={[
                            {
                                required: true,
                                message: 'Building Name is required!',
                            },
                        ]}
                    >
                        <Input placeholder="Building Name" />
                    </Form.Item>
                    <Form.Item
                        name="postcode"
                        rules={[
                            {
                                required: true,
                                message: 'Postcode is required!',
                            },
                        ]}
                    >
                        <Input placeholder="Postcode" />
                    </Form.Item>
                </div>
                <div className="d-50 m-100">
                    <Form.Item
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Country is required!',
                            },
                        ]}
                    >
                        <AgoraSelect
                            placeholder="Country"
                            options={ddlData.ddlCountry}
                            onChange={value => {
                                handleOnChangeCountry(value, "ddlStateAssessment")
                                setCountrySelected(value ? true : false)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: 'State is required!',
                            },
                        ]}
                    >
                        <AgoraSelect
                            disabled={previewOnly === true ? true : countrySelected === false ? true : false}
                            placeholder={countrySelected === false ? "Please select country" : "Select state"}
                            options={ddlData.ddlStateAssessment}
                        />
                    </Form.Item>
                    <Form.Item
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: 'City is required!',
                            },
                        ]}
                    >
                        <Input placeholder="City" />
                    </Form.Item>
                </div>
            </div>
        },
        {
            no: "5",
            text: "Country of incorporation",
            input: <Form.Item
                name="countryIncorporation"
                rules={[
                    {
                        required: true,
                        message: 'Country of incorporation is required!',
                    },
                ]}
            >
                <AgoraSelect
                    options={ddlData.ddlCountry}
                />
            </Form.Item>
        },
        {
            no: "6",
            text: "Telephone Number",
            input: <Form.Item
                name="telephone"
                rules={[
                    {
                        required: true,
                        message: 'Telephone Number is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "7",
            text: "Website URL",
            input: <Form.Item
                name="url"
                rules={[
                    {
                        required: true,
                        message: 'Website URL is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "8",
            text: "Products or services supplied to StarHub",
            info: "(Indicate main products and services)",
            guide: "Check all that apply.",
            input: <>
                <Form.Item name="mobileChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Mobile Handset</Checkbox>
                </Form.Item>
                <Form.Item name="ithardwareChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>IT Hardware</Checkbox>
                </Form.Item>
                <Form.Item name="logisticsChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Logistics Services</Checkbox>
                </Form.Item>
                <Form.Item name="packagingChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Packaging Services</Checkbox>
                </Form.Item>
                <Form.Item name="equipmentChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Equipment</Checkbox>
                </Form.Item>
                <Form.Item name="professionalChk" valuePropName="checked" style={{ marginBottom: "0px" }}>
                    <Checkbox>Professional Services</Checkbox>
                </Form.Item>
                <div className="i-x-left i-y-center">
                    <Form.Item name="othersChk" valuePropName="checked">
                        <Checkbox>Other:</Checkbox>
                    </Form.Item>
                    <Form.Item name="othersRemark" style={{ width: "calc(100% - 114px)" }}>
                        <Input />
                    </Form.Item>
                </div>
            </>
        },
        {
            no: "9",
            text: "Name",
            info: "(Of authorized and registered person in sustainability)",
            input: <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Name is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "10",
            text: "Job Title",
            info: "(Of authorized and registered person in sustainability)",
            input: <Form.Item
                name="jobTitle"
                rules={[
                    {
                        required: true,
                        message: 'Job Title is required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 2a : Is your company participant of the Responsible Business Alliance (RBA)"
        },
        {
            no: "11",
            text: "Is your company participant of the Responsible Business Alliance (RBA)?",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="rbachk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 12</span></Radio>
                        <Radio value={false}>No</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 2b : United Nations Global Compact (UNGC)"
        },
        {
            no: "12",
            text: "United Nations Global Compact (UNGC)",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="ungcchk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 2c : Greenhouse Gas (GHG) Emissions"
        },
        {
            no: "13",
            text: "Does your company have a science-based greenhouse gas (GHG) emissions reduction target validated by the Science-Based Targets Initiative (SBTI)?",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="sbtichk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            no: "14",
            text: "Has your company set a Net Zero Target to be met by 2050 or earlier?",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="targetChk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            no: "15",
            text: "Does your company publicly disclose its annual GHG emissions (independently or within other reports e.g. Annual Report, CSR Report or Sustainability Report)?",
            info: "",
            input: <>
                <Form.Item
                    name="discloseChk"
                    rules={[{ required: true, message: 'Please mark an oval!' }]}
                >
                    <Radio.Group
                        onChange={event => {
                            setRequiredByAction(prev => ({
                                ...prev,
                                question15: event.target.value
                            }))
                        }}
                    >
                        <Space direction="vertical">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 17</span></Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <p style={{ fontSize: "14px", margin: "25px 0px 20px" }}>Noted that your company publicly discolose its annual GHG emissions</p>
            </>
        },
        {
            no: "16",
            text: "Please provide a link to the reports e.g. Annual Report, CSR Repott or Sustainability Report)?",
            required: requiredByAction.question15,
            input: <Form.Item
                name="reportLink"
                rules={[
                    {
                        required: requiredByAction.question15,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input.TextArea showCount maxLength={250} />
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 3 : Compliance"
        },
        {
            no: "17",
            text: "Does your company comply with the following provisions of the StarHub Supplier Code of Conduct?",
            guide: "Mark only one oval per row",
            input:
                <div className="tbl-answer">
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Human rights</div>
                        <Form.Item
                            name="humanRightsChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Harassment and abuse</div>
                        <Form.Item
                            name="harrassmentChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Freedom of association and Collective bargaining</div>
                        <Form.Item
                            name="freedomChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Forced labour</div>
                        <Form.Item
                            name="forcedChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Child labour</div>
                        <Form.Item
                            name="childChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Working hours</div>
                        <Form.Item
                            name="workingChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Compensation</div>
                        <Form.Item
                            name="compensationChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Non-discrimination</div>
                        <Form.Item
                            name="noDiscChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Health and Safety</div>
                        <Form.Item
                            name="healthChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Environmental protection</div>
                        <Form.Item
                            name="environmentChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="tbl-answer-row i-x-left i-y-center">
                        <div className="tbl-answer-row-title">Anti-corruption</div>
                        <Form.Item
                            name="antiCorrChk"
                            rules={[{ required: true, message: 'Please mark an oval!' }]}
                            style={{ margin: "0px" }}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>
        },
        {
            no: "18",
            text: "If there's any 'no' to above, please elaborate",
            info: "Else indicate 'NA'",
            input: <Form.Item
                name="noRemark"
                rules={[
                    {
                        required: true,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input.TextArea showCount maxLength={250} />
            </Form.Item>
        },
        {
            no: "19",
            text: "Does your company have a code of conduct or policy statement covering the same aspects as the StarHub Supplier Code of Conduct?",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="policyChk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            no: "20",
            text: "If there's any 'no' to above, please elaborate else indicate 'NA'",
            input: <Form.Item
                name="policyRemark"
                rules={[
                    {
                        required: true,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input.TextArea showCount maxLength={250} />
            </Form.Item>
        },
        {
            no: "21",
            text: "Has StarHub's Responsible Sourcing Policy and Supplier Code of Conduct been communicated to your suppliers?",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="sourcingPolicyChk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            no: "22",
            text: "If there's any 'no' to above, please elaborate else indicate 'NA'",
            input: <Form.Item
                name="sourcingPolicyRemark"
                rules={[
                    {
                        required: true,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input.TextArea showCount maxLength={250} />
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 4 : Screening/Assessment"
        },
        {
            no: "23",
            text: "Has your company identified and assessed any actual or potential adverse impacts in relation to the following?",
            guide: "Mark only one oval per row.",
            input: <div className="tbl-answer">
                <div className="tbl-answer-row i-x-left i-y-center">
                    <div className="tbl-answer-row-title">Use of scarce natural resources, energy and water</div>
                    <Form.Item
                        name="useChk"
                        rules={[{ required: true, message: 'Please mark an oval!' }]}
                        style={{ margin: "0px" }}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="tbl-answer-row i-x-left i-y-center">
                    <div className="tbl-answer-row-title">Noise, air-pollutant and odor emissions, soil and water contamination</div>
                    <Form.Item
                        name="noiseChk"
                        rules={[{ required: true, message: 'Please mark an oval!' }]}
                        style={{ margin: "0px" }}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="tbl-answer-row i-x-left i-y-center">
                    <div className="tbl-answer-row-title">Waste management and product packaging</div>
                    <Form.Item
                        name="wasteChk"
                        rules={[{ required: true, message: 'Please mark an oval!' }]}
                        style={{ margin: "0px" }}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="tbl-answer-row i-x-left i-y-center">
                    <div className="tbl-answer-row-title">Human rights</div>
                    <Form.Item
                        name="humanRightsScreenChk"
                        rules={[{ required: true, message: 'Please mark an oval!' }]}
                        style={{ margin: "0px" }}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="tbl-answer-row i-x-left i-y-center">
                    <div className="tbl-answer-row-title">Health and safety</div>
                    <Form.Item
                        name="healthSafeChk"
                        rules={[{ required: true, message: 'Please mark an oval!' }]}
                        style={{ margin: "0px" }}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
            </div>
        },
        {
            no: "24",
            text: "Please elaborate potential adverse impacts where relevant, e",
            input: <Form.Item
                name="potentialRemark"
                rules={[
                    {
                        required: true,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Input.TextArea showCount maxLength={250} />
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 5a : Certification"
        },
        {
            no: "25",
            text: "Has your company been certified on social issues by external certification organization? (e.g. SA8000 or similar?)",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="externalCertAchk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group
                    onChange={event => {
                        setRequiredByAction(prev => ({
                            ...prev,
                            question25: event.target.value
                        }))
                    }}
                >
                    <Space direction="vertical">
                        <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 26</span></Radio>
                        <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 29</span></Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 5a : Certification Information"
        },
        {
            no: "26",
            text: "Certification name",
            required: requiredByAction.question25,
            input: <Form.Item name="externalCertName"
                rules={[{ required: requiredByAction.question25, message: 'Certification name is required!' }]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "27",
            text: "Certification number",
            required: requiredByAction.question25,
            input: <Form.Item name="externalCertNo"
                rules={[{ required: requiredByAction.question25, message: 'Certification number is required!' }]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "28",
            text: "Validity Period",
            info: "Please enter the expiration date",
            required: requiredByAction.question25,
            input: <Form.Item
                name="externalValidPeriod"
                rules={[{ required: requiredByAction.question25, message: 'Validity period is required!' }]}
            >
                <DatePicker
                    format="DD/MM/YYYY"
                />
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 5b : Certification"
        },
        {
            no: "29",
            text: "Does your company implement a certified Environmental Management System? (e.g. ISO 14001)",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="certifiedEnvironmentChk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group
                    onChange={event => {
                        setRequiredByAction(prev => ({
                            ...prev,
                            question29: event.target.value
                        }))
                    }}
                >
                    <Space direction="vertical">
                        <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 30</span></Radio>
                        <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 33</span></Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 5b : Certification Information"
        },
        {
            no: "30",
            text: "Certification name",
            required: requiredByAction.question29,
            input: <Form.Item name="environmentCertName"
                rules={[{ required: requiredByAction.question29, message: 'Certification name is required!' }]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "31",
            text: "Certification number",
            required: requiredByAction.question29,
            input: <Form.Item name="environmentCertNo"
                rules={[{ required: requiredByAction.question29, message: 'Certification number is required!' }]}>
                <Input />
            </Form.Item>
        },
        {
            no: "32",
            text: "Validity Period",
            info: "Please enter the expiration date",
            required: requiredByAction.question29,
            input: <Form.Item
                name="environmentValidPeriod"
                rules={[{ required: requiredByAction.question29, message: 'Validity period is required!' }]}
            >
                <DatePicker
                    format="DD/MM/YYYY"
                />
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 5c : Certification"
        },
        {
            no: "33",
            text: "Does your company implement a certified Occupational Health and Safety management System? (e.g. ISO 45001 or OHSAS 18001 certified)",
            guide: "Mark only one oval.",
            input: <Form.Item
                name="humanRightsChk"
                rules={[{ required: true, message: 'Please mark an oval!' }]}
            >
                <Radio.Group
                    onChange={event => {
                        setRequiredByAction(prev => ({
                            ...prev,
                            question33: event.target.value
                        }))
                    }}
                >
                    <Space direction="vertical">
                        <Radio value={true}>Yes <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Continue question 34</span></Radio>
                        <Radio value={false}>No <span style={{ fontSize: "12px", marginLeft: "15px", fontStyle: "italic" }}>Skip to question 37</span></Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        },
        {
            type: "section",
            text: "Section 5c : Certification Information"
        },
        {
            no: "34",
            text: "Certification name",
            required: requiredByAction.question33,
            input: <Form.Item name="healthCertName"
                rules={[{ required: requiredByAction.question33, message: 'Certification name is required!' }]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "35",
            text: "Certification number",
            required: requiredByAction.question33,
            input: <Form.Item name="healthCertNo"
                rules={[{ required: requiredByAction.question33, message: 'Certification number is required!' }]}
            >
                <Input />
            </Form.Item>
        },
        {
            no: "36",
            text: "Validity Period",
            info: "Please enter the expiration date",
            required: requiredByAction.question33,
            input: <Form.Item
                name="healthValidPeriod"
                rules={[{ required: requiredByAction.question33, message: 'Validity period is required!' }]}
            >
                <DatePicker
                    format="DD/MM/YYYY"
                />
            </Form.Item>
        },
        {
            type: "section",
            text: "Declaration"
        },
        {
            no: "37",
            text: "As the authorised and registered person, I hereby confirm on behalf of the company that the content and statements in the completed questionnaire give a truthful and accurate description of compliance with StarHub's Supplier Code of Conduct.",
            guide: "Mark only one oval.",
            input: <Form.Item name="declaration" valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                    },
                ]}
            >
                <Checkbox>I agree</Checkbox>
            </Form.Item>
        }
    ]
    return (
        <>
            <Form
                form={form}
                name="application-form"
                size="middle"
                layout="vertical"
                requiredMark={'optional'}
                onFinish={handleOnSubmitForm}
                scrollToFirstError
                disabled={previewOnly}
            >
                <FormSection title="Sustainability Self-Assessment">
                    <div className="questionnaire">
                        <div className="questionnaire-title">
                            StarHub's Supplier Self-assessment Questionnaire
                        </div>
                        <div className="questionnaire-content">
                            <p>Dear Suppliers,</p>
                            <p>
                                Our company is committed to conducting our business in a sustainable and
                                environmentally responsible manner. This includes respect for universally recognized
                                principles on human rights, the environment, and anti-corruption. We seek to ensure that
                                our own company and our suppliers operate in accordance with the requirement outlined
                                in our Supplier Code of Conduct. Please complete the supplier self-assessment
                                questionnaire to report how your company manages its impacts on human rights
                                including labor rights, environmental and anti-corruption principles. We kindly request you
                                read <Link to="https://tinyurl.com/supplier-code-of-conduct" target="_blank" rel="noopener noreferrer">Supplier Code of Conduct</Link> carefully before answering this questionnaire. The Supplier Code of Conduct will guide
                                your understanding of context and content of the questions.
                            </p>
                            <p>
                                If you have any questions regarding our Code of Conduct or the questionnaire, please do
                                not hesitate to contact us.
                            </p>
                            <p>
                                Kind Regards <br />
                                StarHub Procurement<br />
                                <a href="mailto:procurement@starhub.com" target="_blank" rel="noopener noreferrer">procurement@starhub.com</a>
                            </p>
                        </div>
                    </div>
                    <Questions items={SustainabilitySelfAssessment} />
                </FormSection>
            </Form>
            <style>{`
                .questionnaire{
                    background-color: #f5f5f5;
                    border-radius: 6px;
                    padding: 20px 25px 5px;
                    margin-top: 10px;
                    margin-right: 25px;
                    margin-bottom: 20px;
                }
                .questionnaire-title{
                    font-size: 25px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    font-weight: bold;
                }
            `}</style>
        </>
    )
}

export default QuestionV1