import React from 'react'
import StickyBox from "react-sticky-box";

import './PageHeader.css'

const PageHeader = ({ title, subtitle, type, style, children }) => {
    return (
        <>
            <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 100 }}>
                <div className={`page-header i-x-space-between i-y-center ${type}`} style={style}>
                    <div className="page-title-container">
                        <div className="page-title">{title}</div>
                        {subtitle && <div className="page-subtitle">{subtitle}</div>}
                    </div>
                    <div className="page-header-action i-x-right i-y-center">
                        {children}
                    </div>
                </div>
            </StickyBox>
        </>
    )
}

export default PageHeader