import SideMenuItems from '../utils/SideMenuItems';

export const SideMenuFilterByRole = (role) => {
    return SideMenuItems
        .filter(menu1 => !(role === "Requester" && (menu1.key === "maintenance")))
        .map(menu1 => {
            // Condition to hide the entire 'MAINTENANCE' group for 'Requester'
            if (role === "Requester" && menu1.key === "maintenance") {
                menu1.visible = "false";
            }

            if (menu1.children) {
                menu1.children = menu1.children.map(menu2 => {
                    // Condition to hide 'mytask_listapproval' for 'Admin' or 'Requester'
                    if (menu2.key === "mytask_listapproval" && (role === "Admin" || role === "Requester")) {
                        menu2.visible = "false"; // Hide list approval for Admin or Requester
                    } else {
                        menu2.visible = "true";
                    }

                    if (menu2.children) {
                        menu2.children = menu2.children.map(menu3 => {
                            menu3.visible = "true";
                            return menu3;
                        });
                    }

                    return menu2;
                });
            }
            return menu1;
        });
};
