import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Badge, Button, Descriptions, Form, message, Tabs } from 'antd';
import dayjs from 'dayjs';

import Main from '../../../components/Main';
import Container from '../../../components/Container';
import PageHeader from '../../../components/PageHeader';
import WFNewApplication from '../../../components/Workflow/WFNewApplication';
import Attachment from '../../../components/Attachment';
import VendorInfo from './VendorInfo';
import Assessment from './Assessment';
import ReactStickyBox from 'react-sticky-box';

import './VendorDetails.css';

export default function Registration() {
    const { vendorId } = useParams();
    const [vendorInfoForm] = Form.useForm();
    const [assessmentForm] = Form.useForm();

    const [state, setState] = useState({
        appId: 0,
        previewOnly: true,
        ddlData: {},
        vendorDetails: {},
        vendorInfo: {},
        assessmentInfo: {},
    });

    const handleOnChangeCountry = (value, ddlName) => {
        axios.get(`Country/GetState/${value}`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                setState(prevState => ({
                    ...prevState,
                    ddlData: {
                        ...prevState.ddlData,
                        [ddlName]: resData.Data
                    },
                }))
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }

    useEffect(() => {
        getPageLoad()
        // eslint-disable-next-line
    }, [])

    const stringToBoolean = (str) => {
        if (str === "1") {
            return true
        }
        return false;
    }

    const getPageLoad = () => {
        axios.get(`VendorListing/View/${vendorId}`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                const vendorInfo = resData.Data.newVendorDetails.tbVendor
                const formatedVendorInfo = {
                    vendorId: vendorInfo.VendorId,
                    vendorType: vendorInfo.VendorType && vendorInfo.VendorType !== "" ? vendorInfo.VendorType : "Local",
                    vendorBrn: vendorInfo.VendorBrn,
                    vendorAccount: vendorInfo.VendorAccount,
                    vendorTitle: vendorInfo.VendorTitle && vendorInfo.VendorTitle !== "" ? vendorInfo.VendorTitle : undefined,
                    vendorName: vendorInfo.VendorName,
                    vendorName2: vendorInfo.VendorName2,
                    vendorName3: vendorInfo.VendorName3,
                    vendorName4: vendorInfo.VendorName4,
                    acra: vendorInfo.Acra,
                    porg: vendorInfo.Porg && vendorInfo.Porg !== "" ? vendorInfo.Porg : undefined,
                    accountGroup: vendorInfo.AccountGroup && vendorInfo.AccountGroup !== "" ? vendorInfo.AccountGroup : undefined,
                    searchTerms1: vendorInfo.SearchTerms1,
                    searchTerms2: vendorInfo.SearchTerms2,
                    street1: vendorInfo.Street1,
                    street2: vendorInfo.Street2,
                    street3: vendorInfo.Street3,
                    postcode: vendorInfo.Postcode,
                    city: vendorInfo.City,
                    state: vendorInfo.State && vendorInfo.State !== 0 ? vendorInfo.State : undefined,
                    country: vendorInfo.Country && vendorInfo.Country !== 0 ? vendorInfo.Country : undefined,
                    timeZone: vendorInfo.TimeZone && parseInt(vendorInfo.TimeZone) !== 0 ? parseInt(vendorInfo.TimeZone) : undefined,
                    language: vendorInfo.Language && vendorInfo.Language !== "" ? vendorInfo.Language : undefined,
                    commTelephone: vendorInfo.CommTelephone,
                    commMobile: vendorInfo.CommMobile,
                    fax: vendorInfo.Fax,
                    commEmail: vendorInfo.CommEmail,
                    remittanceEmail: vendorInfo.RemittanceEmail,
                    orderCurrency: vendorInfo.OrderCurrency && vendorInfo.OrderCurrency !== "" ? vendorInfo.OrderCurrency : undefined,
                    termsOfPayment: vendorInfo.TermsOfPayment && vendorInfo.TermsOfPayment !== "" ? vendorInfo.TermsOfPayment : undefined,
                    conMobile: vendorInfo.ConMobile,
                    paymentTermRemarks: vendorInfo.PaymentTermRemarks,
                    salesperson: vendorInfo.Salesperson,
                    salesTelephone: vendorInfo.SalesTelephone,
                    bankCountry: vendorInfo.BankCountry && vendorInfo.BankCountry !== "" ? vendorInfo.BankCountry : undefined,
                    accountName: vendorInfo.AccountName,
                    swiftCode: vendorInfo.SwiftCode,
                    bankAccountNo: vendorInfo.BankAccountNo,
                }
                vendorInfoForm.setFieldsValue(formatedVendorInfo);
                if (formatedVendorInfo.country) {
                    handleOnChangeCountry(formatedVendorInfo.country, "ddlStateVendor")
                }
                const assessmentData = resData.Data.newApplicationFormDetails
                const formatedAssessmentData = {
                    companyName1: assessmentData.companyName1,
                    companyName2: assessmentData.companyName2,
                    companyName3: assessmentData.companyName3,
                    companyName4: assessmentData.companyName4,
                    brn: assessmentData.brn,
                    address1: assessmentData.address1,
                    address2: assessmentData.address2,
                    address3: assessmentData.address3,
                    postcode: assessmentData.postcode,
                    city: assessmentData.city,
                    state: assessmentData.state && parseInt(assessmentData.state) !== 0 ? parseInt(assessmentData.state) : undefined,
                    country: parseInt(assessmentData.country) && parseInt(assessmentData.country) !== 0 ? parseInt(assessmentData.country) : undefined,
                    countryIncorporation: parseInt(assessmentData.countryIncorporation) && parseInt(assessmentData.countryIncorporation) !== 0 ? parseInt(assessmentData.countryIncorporation) : undefined,
                    websiteUrl: assessmentData.websiteURL,
                    facilityChk: stringToBoolean(assessmentData.facilityChk),
                    networkChk: stringToBoolean(assessmentData.networkChk),
                    mobileChk: stringToBoolean(assessmentData.mobileChk),
                    ithardwareChk: stringToBoolean(assessmentData.ithardwareChk),
                    itsoftwareChk: stringToBoolean(assessmentData.itsoftwareChk),
                    logisticsChk: stringToBoolean(assessmentData.logisticsChk),
                    professionalChk: stringToBoolean(assessmentData.professionalChk),
                    marketingChk: stringToBoolean(assessmentData.marketingChk),
                    othersChk: stringToBoolean(assessmentData.othersChk),
                    othersRemark: assessmentData.othersRemark,
                    susMatterChk: stringToBoolean(assessmentData.susMatterChk),
                    susMatterName: assessmentData.susMatterName,
                    susMatterTitle: assessmentData.susMatterTitle,
                    susMatterEmail: assessmentData.susMatterEmail,
                    susMatterPhoneNo: assessmentData.susMatterPhoneNo,
                    personFillChk: stringToBoolean(assessmentData.personFillChk),
                    personFillName: assessmentData.personFillName,
                    personFillTitle: assessmentData.personFillTitle,
                    personFillEmail: assessmentData.personFillEmail,
                    personFillPhoneNo: assessmentData.personFillPhoneNo,
                    ungcchk: stringToBoolean(assessmentData.ungcchk),
                    rbachk: stringToBoolean(assessmentData.rbachk),
                    airNoiseChk: assessmentData.airNoiseChk,
                    energyClimateChk: assessmentData.energyClimateChk,
                    waterWasteChk: assessmentData.waterWasteChk,
                    wasteChk: assessmentData.wasteChk,
                    useChk: assessmentData.useChk,
                    chemicalChk: assessmentData.chemicalChk,
                    occupationChk: assessmentData.occupationChk,
                    forcedChk: assessmentData.forcedChk,
                    workingHoursChk: assessmentData.workingHoursChk,
                    nonDiscChk: assessmentData.nonDiscChk,
                    freedomChk: assessmentData.freedomChk,
                    humanRightChk: assessmentData.humanRightChk,
                    fraudChk: assessmentData.fraudChk,
                    dataPrivacyChk: assessmentData.dataPrivacyChk,
                    supplierChk: assessmentData.supplierChk,
                    iSO14001Chk: stringToBoolean(assessmentData.iSO14001Chk),
                    iSO50001Chk: stringToBoolean(assessmentData.iSO50001Chk),
                    iSO45001Chk: stringToBoolean(assessmentData.iSO45001Chk),
                    iSO14064Chk: stringToBoolean(assessmentData.iSO14064Chk),
                    iSO27001Chk: stringToBoolean(assessmentData.iSO27001Chk),
                    iSO22301Chk: stringToBoolean(assessmentData.iSO22301Chk),
                    sA8000Chk: stringToBoolean(assessmentData.sA8000Chk),
                    bCropChk: stringToBoolean(assessmentData.bCropChk),
                    tAFEPChk: stringToBoolean(assessmentData.tAFEPChk),
                    bizChk: stringToBoolean(assessmentData.bizChk),
                    externalOtherChk: stringToBoolean(assessmentData.externalOtherChk),
                    externalOtherRmk: assessmentData.externalOtherRmk,
                    gHGChk: stringToBoolean(assessmentData.gHGChk),
                    gHGRmk: assessmentData.gHGRmk,
                    sbtiChk: stringToBoolean(assessmentData.sbtiChk),
                    climateChk: stringToBoolean(assessmentData.climateChk),
                    targetRmk: assessmentData.targetRmk,
                    gHGPubliclyChk: stringToBoolean(assessmentData.gHGPubliclyChk),
                    url: assessmentData.url,
                    regulatoryChk: stringToBoolean(assessmentData.regulatoryChk),
                    regulatoryRmk: assessmentData.regulatoryRmk,
                    supplierCodeOfConductChk: stringToBoolean(assessmentData.supplierCodeOfConductChk),
                    compareCodeOfConductChk: stringToBoolean(assessmentData.compareCodeOfConductChk),
                    codeOfConductRmk: assessmentData.codeOfConductRmk,
                }
                assessmentForm.setFieldsValue(formatedAssessmentData);
                if (formatedAssessmentData.country) {
                    handleOnChangeCountry(formatedAssessmentData.country, "ddlStateAssessment")
                }

                setState(prevState => ({
                    ...prevState,
                    appId: resData.Data.appId,
                    ddlData: resData.Data.ddlData,
                    vendorDetails: resData.Data.vendorDetails,
                    vendorInfo: formatedVendorInfo,
                    assessmentInfo: formatedAssessmentData,
                }))
                getAttachment(resData.Data.appId)
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }

    const [listAttachment, setListAttachment] = useState([])
    const getAttachment = (appId) => {
        axios.get(`Attachment/PageLoad/${appId}`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                setListAttachment(resData.Data)
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
    }

    const [activeKey, setActiveKey] = useState(0);
    const tabs = [
        {
            tabId: "vendorInfo",
            title: 'Vendor Information',
            content: <VendorInfo
                form={vendorInfoForm}
                previewOnly={state.previewOnly}
                ddlData={state.ddlData}
                handleOnChangeCountry={handleOnChangeCountry}
            />,
        },
        {
            tabId: "assessment",
            title: 'Sustainablility Self-Assessment',
            content: <Assessment
                form={assessmentForm}
                previewOnly={state.previewOnly}
                ddlData={state.ddlData}
                handleOnChangeCountry={handleOnChangeCountry}
            />,
        },
        {
            tabId: "attachment",
            title: 'Attachment',
            content: <Attachment
                appId={state.appId}
                title={`Upload Attachment`}
                previewOnly={state.previewOnly}
                listAttachment={listAttachment}
                setListAttachment={setListAttachment}
            />,
        },
    ]
    return (
        <Main listBreadcrumb={[
            { key: "1", title: "Report" },
            { key: "2", title: "Vendor Listing", link: "/report/vendorlisting" },
            { key: "3", title: "Vendor Details" },
        ]} >
            <Container>
                <PageHeader
                    title="Vendor Details"
                    subtitle="All the information down below related to vendor registred data."
                    style={{ marginBottom: "0px" }}
                >
                    {state.appId !== 0
                        && <WFNewApplication appId={state.appId}>
                            <Button className="table-btn default" block
                            >Workflow History</Button>
                        </WFNewApplication>
                    }
                </PageHeader>
                <Descriptions size="small"
                    column={{ md: 2, sm: 1, xs: 1 }}
                    style={{ margin: "15px 0px 10px" }}
                >
                    <Descriptions.Item label="Vendor Name">{state.vendorDetails.vendorName}</Descriptions.Item>
                    <Descriptions.Item label="Status">
                        <Badge
                            text={state.vendorDetails.vendorStatus}
                            status={state.vendorDetails.vendorStatus?.includes("Pending") ? "processing"
                                : state.vendorDetails.vendorStatus === "New Invitation" ? "warning"
                                    : state.vendorDetails.vendorStatus === "Acknowledged" ? "success"
                                        : "error"
                            }
                        />

                    </Descriptions.Item>
                    <Descriptions.Item label="Nationality">{state.vendorDetails.nationality}</Descriptions.Item>
                    <Descriptions.Item label="Registration Date">{state.vendorDetails.vendorRegistrationDate ? dayjs(state.vendorDetails.vendorRegistrationDate, { utc: true }).format("DD/MM/YYYY HH:mm:ss A") : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Company BRN" span={2}>{state.vendorDetails.vendorCompBRN}</Descriptions.Item>
                    <Descriptions.Item label="Vendor Email">{state.vendorDetails.vendorEmail}</Descriptions.Item>
                </Descriptions>
                <ReactStickyBox offsetTop={67} offsetBottom={20} style={{ zIndex: 100 }}>
                    <div className="vendor-details-tabs">
                        <Tabs
                            activeKey={activeKey}
                            onChange={newActiveKey => setActiveKey(newActiveKey)}
                            items={tabs
                                .map((item, index) => ({
                                    key: index,
                                    label: item.title
                                }))}
                        />
                    </div>
                </ReactStickyBox>
                {tabs[activeKey].content}
            </Container>
        </Main >
    )
}