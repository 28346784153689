import React from 'react'
import { Select } from 'antd';

function AgoraSelect(props) {
    const { ...rest } = props;
    return (
        <Select
            placeholder="Please select"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            {...rest}
        />
    )
}

export default AgoraSelect