import React, { useEffect } from 'react';
import AgoraLogo from '../assets/images/Agora_Header.png';

import { Layout } from 'antd';

const { Header, Content } = Layout

const MainNoLogin = ({ title, children }) => {
    useEffect(() => {
        document.title = `Agora VRMS - ` + title || "";
    }, [title]);
    return (
        <>
            <Layout>
                <Header
                    className={`i-x-space-between i-y-center`}
                    style={{
                        background: '#ffffff',
                        borderBottom: "1px solid #eeeeee",
                        lineHeight: "unset",
                        height: "70px",
                        padding: "0px 35px"
                    }}
                >
                    <div className="main-nl-header-left  i-x-left i-y-center">
                        <div className="main-nl-logo-img i-x-left i-y-center">
                            <div className="main-nl-logo-img">
                                <img src={AgoraLogo} alt="Agora Logo" />
                            </div>
                            <div className="main-nl-logo-system">
                                <div className="main-nl-logo-title">VRMS</div>
                                <div className="main-nl-logo-subtitle">Vendor Registration Management System</div>
                            </div>

                        </div>
                    </div>
                    <div className="main-nl-header-right i-x-right i-y-center"></div>
                </Header >
                <Content
                    style={{
                        minHeight: 'calc(100vh - 80px)',
                        backgroundColor: "#fafafa",
                        padding: "30px 35px"
                    }}
                >
                    <div className="i-x-center">
                        <div className="d-75 t-90 m-100">
                            {children}
                        </div>
                    </div>
                </Content>
            </Layout>
            <style>{`
                .main-nl-banner{
                    background-color: #a5d6a7;
                    background-position: center;
                    background-size: cover;
                }
                .main-nl-container{
                    box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px;
                }
                .main-nl-logo-img{
                    height: 46px;
                }
                .main-nl-logo-img img{
                    height: 100%;
                }
                .main-nl-logo-system{
                    border-left: 1px solid gainsboro;
                    margin-left: 20px;
                    padding-left: 20px;
                    color: #303f9f;
                }
                .main-nl-logo-title{
                    font-size: 30px;
                    line-height: 30px;
                }
                .main-nl-logo-subtitle{
                    font-size: 10px;
                    font-style: italic;
                }
            `}</style>
        </>
    )
}

export default MainNoLogin