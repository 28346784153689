import { Alert, Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import MainLogin from '../layouts/MainLogin'

const ResetPassword = () => {
    const { userid, tempPassword } = useParams();

    const [successUpdatePassword, setSuccessUpdatePassword] = useState(false)
    const [btnUpdateLoading, setBtnUpdateLoading] = useState(false)
    const [updateErrorMsg, setUpdateErrorMsg] = useState("")
    const onClickBtnUpdate = (formData) => {
        setBtnUpdateLoading(true)
        axios.post(`/LoginVRMS/ResetPassword`, {
            userId: userid,
            tempPass: tempPassword,
            newPass: formData.newPassword
        }).then(res => {
            setBtnUpdateLoading(false)
            if (res.data.Status === "True") {
                setSuccessUpdatePassword(true)
            } else {
                setUpdateErrorMsg(res.data.Message)
            }
        }).catch(error => {
            console.log(error)
            setUpdateErrorMsg(error.message)
        })
    }
    return (
        successUpdatePassword
            ? <MainLogin title={'Password Updated'}
                subtitle="Successful update your password, please proceed to login page to start using VRMS."
            >
                <Link to="/">
                    <Button className="d-100 m-100 login-form-button" type="primary" size="large">Login</Button>
                </Link>
            </MainLogin>
            : <MainLogin title="Create new password"
                subtitle="Protect your account with a unique password at least 8 characters long.">
                <Form
                    name="forgotpassword"
                    autoComplete={false}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={onClickBtnUpdate}
                >
                    {updateErrorMsg !== ""
                        && <Alert message={updateErrorMsg} type="error" showIcon style={{ marginRight: "25px", marginBottom: "20px" }} />
                    }
                    <Form.Item
                        label="New Password"
                        name="newPassword"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your new password!',
                            },
                            {
                                message: 'At least 8 characters in length!',
                                min: 8,
                            },
                        ]}
                    >
                        <Input type="password" />
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your new password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input type="password" />
                    </Form.Item>
                    <Form.Item>
                        <Button className="d-100 m-100 login-form-button" type="primary" size="large" htmlType="submit" style={{ marginBottom: "15px" }}
                            loading={btnUpdateLoading}
                        >
                            Update Password
                        </Button>
                        <Link to="/"> <Button className="d-100 m-100 login-form-button" type="default" size="large" >
                            Back to Login
                        </Button>
                        </Link>
                    </Form.Item>
                </Form >
            </MainLogin >
    )
}

export default ResetPassword