import React, { useEffect } from 'react';
import AgoraLogo from '../assets/images/Agora_Header.png';
import StrateqLogo from '../assets/images/strateq-logo-new.png';
import StarHubLogo from '../assets/images/Starhublogo.png';
import StarHubBackground from '../assets/images/Starhub-Login-Backgroud.png';
import { CopyrightOutlined } from '@ant-design/icons';

const MainLogin = ({ title, subtitle, children }) => {
    useEffect(() => {
        document.title = `Agora VRMS - ` + title || "";
    }, [title]);
    return (
        <>
            <div className="main-wl-container i-x-left i-x-wrap">
                <div className="main-wl-left d-60 t-70 m-100">
                    <div className="main-wl-form  i-x-center">
                        <div className="d-45 t-80 m-90">
                            <div className="main-wl-form-logo i-x-left">
                                <img src={AgoraLogo} alt="Agora Logo" />
                                <div className="main-wl-form-logo-title">
                                    <div className="main-wl-form-logo-title-short">VRMS</div>
                                    <div className="main-wl-form-logo-title-long">Vendor Registration Management System</div>
                                </div>
                            </div>
                            <div className="main-wl-title">{title}</div>
                            <div className="main-wl-subtitle">{subtitle}</div>
                            {children}
                        </div>
                    </div>
                    <div className="main-wl-poweredby">
                        <div className="main-wl-poweredby-title">Powered By:</div>
                        <div className='main-wl-poweredby-logo i-x-center i-y-center'>
                            <img className="starhub-logo" src={StarHubLogo} alt="Agora Logo" />
                            <img className="strateq-logo" src={StrateqLogo} alt="Agora Logo" />
                        </div>
                    </div>
                    <div className="main-wl-footer i-x-center i-y-center">
                        <CopyrightOutlined style={{ marginRight: "5px", marginTop: "-3px" }} /> 2023 Agora VRMS. All rights reserved.
                    </div>
                </div>
                <div className="main-wl-right d-40 t-30 m-0
                
                " style={{ backgroundImage: `url(${StarHubBackground})` }}></div>
            </div>
            <style>{`
                .main-wl-container{
                    height: 100vh;
                }
                .main-wl-left{
                    box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px;
                    z-index: 1;
                }
                .main-wl-right{
                    background-color: #a5d6a7;
                    background-position: center;
                    background-size: cover;
                }
                .main-wl-form{
                    min-height: calc(100vh - 200px);
                    padding: 160px 0px 0px;
                }
                .main-wl-form-logo{
                    height: 52px;
                    margin-bottom: 50px;
                }
                .main-wl-form-logo-title{
                    border-left: 1px solid gainsboro;
                    margin-left: 20px;
                    padding-left: 20px;
                    color: #303f9f;
                }
                .main-wl-form-logo-title-short{
                    font-size: 40px;
                    line-height: 37px;
                    margin-bottom: 2px;
                }
                .main-wl-form-logo-title-long{
                    font-size: 10px;
                    font-style: italic;
                }
                .main-wl-title{
                    color: #1b5e20;
                    font-size: 30px;
                    margin-bottom: 5px;
                }
                .main-wl-subtitle{
                    color: #4caf50;
                    margin-bottom: 35px;
                    font-size: 14px;
                }
                .main-wl-terms{
                    text-align: center;
                    color: #757575;
                }
                .main-wl-terms-title{
                    font-size: 20px;
                    font-weight: bold;
                    border-bottom: 1px solid gainsboro;
                    padding-bottom: 10px;
                    margin-bottom: 10px
                }
                .main-wl-terms-body{
                    text-align: justify;
                }
                .main-wl-poweredby{
                    height: 100px;
                    background-color: #ffffff;
                    padding: 16px 0px;
                    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
                }
                .main-wl-poweredby-title{
                    text-align: center;
                    font-weight: bold;
                }
                .main-wl-poweredby-logo{
                    height: 60px;
                }
                .main-wl-poweredby-logo .starhub-logo{
                    height: 100%;
                }
                .main-wl-poweredby-logo .strateq-logo{
                    height: 75%;
                    margin-top: 8px;
                }
                .main-wl-footer{
                    height: 100px;
                    color: #4caf50;
                }
                
                @media (min-width: 300px) and (max-width: 640px) {
                    .main-wl-left{
                        width: 100vw;
                    }
                    .main-wl-right{
                        width: 100vw;
                    }
                    .main-wl-form{
                        padding: 100px 0px 0px;
                    }
                }
            `}</style>
        </>
    )
}

export default MainLogin