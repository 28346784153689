import React, { useEffect, useState } from 'react'
import { Button, Descriptions, Drawer, message, Tag, Timeline } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons';
import ContainerSection from '../ContainerSection';
import './WFNewApplication.css';
import axios from 'axios';
import dayjs from 'dayjs';

function WFNewApplication({ appId, children }) {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [gvWfDetails, setGvWfDetails] = useState({})
    const [gvWFTransHistory, setGvWorkflowHistory] = useState([])
    useEffect(() => {
        axios.get(`/WorkFlow/${appId}`).then(res => {
            let resData = res.data
            if (resData.Status === "True") {
                setGvWfDetails(resData.Data.gvWfDetails)
                setGvWorkflowHistory(resData.Data.gvWFTransHistory)
            } else {
                message.error(resData.Message)
            }
        }).catch(error => {
            console.log(error)
            message.error(error.message)
        })
        // eslint-disable-next-line
    }, [])

    const TimelineContent = ({ status, actionby, actionDate, remark }) => <>
        <div className="timeline-item">
            <div className="timeline-action i-x-left">
                <div className="timeline-action-lable">Action By</div>
                <div className="timeline-action-desc">{actionby || "-"}</div>
            </div>
            <div className="timeline-action i-x-left">
                <div className="timeline-action-lable">Action Date</div>
                <div className="timeline-action-desc">{actionDate || "-"}</div>
            </div>
            {status !== ""
                && <div className="timeline-action i-x-left">
                    <div className="timeline-action-lable">Status</div>
                    <div className="timeline-action-desc">
                        <Tag
                            color={status === "Submitted" || status === "Created" || status === "Approved" || status === "Amended" ? "green"
                                : status === "Rejected" ? "red"
                                    : status === "In Progress" ? "blue"
                                        : "gray"
                            }
                        >{status}</Tag>
                    </div>
                </div>

            }
            <div className="timeline-action i-x-left">
                <div className="timeline-action-lable">Remark</div>
                <div className="timeline-action-desc">{remark || "-"}</div>
            </div>
        </div>
    </>
    return (
        <>
            <span onClick={() => {
                setOpenDrawer(true)
            }}>
                {children || <Button type="primary">Workflow</Button>}
            </span>
            <Drawer title={`Workflow History`}
                width={window.innerWidth > 600 ? 600 : window.innerWidth - 80}
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                bodyStyle={{ padding: "0px" }}
            >
                <ContainerSection title="Workflow Details" style={{ marginTop: "0px" }}>
                    <Descriptions size="small"
                        column={1}
                        bordered={true}
                    >
                        <Descriptions.Item label="Application No" labelStyle={{ width: "170px" }}>{gvWfDetails.appNo}</Descriptions.Item>
                        <Descriptions.Item label="Application Type">{gvWfDetails.appType}</Descriptions.Item>
                        <Descriptions.Item label="Vendor Name">{gvWfDetails.vendorName}</Descriptions.Item>
                    </Descriptions>
                </ContainerSection>
                <ContainerSection title="Workflow Timeline" bodyStyle={{ marginRight: "12px" }}>
                    <Timeline mode="left" >
                        {gvWFTransHistory.map((data, index) =>
                            <Timeline.Item
                                key={index}
                                label={data.wfState}
                                color={data.status === "Submitted" || data.status === "Created" || data.status === "Approved" || data.status === "Amended" ? "green"
                                    : data.status === "Rejected" ? "red"
                                        : data.status === "In Progress" ? "blue"
                                            : "gray"
                                }
                                dot={data.status === "In Progress" && <ClockCircleOutlined style={{ fontSize: '16px' }} />}
                            >
                                <TimelineContent
                                    status={data.status}
                                    actionby={data.actionBy}
                                    actionDate={data.actionDate ? dayjs(data.actionDate, { utc: true }).format("DD/MM/YYYY hh:mm:ss A") : "-"}
                                    remark={data.remarks}
                                />
                            </Timeline.Item>
                        )}
                    </Timeline>
                </ContainerSection>
            </Drawer>
        </>
    )
}

export default WFNewApplication