import React from 'react'
import QuestionV1 from './QuestionV1';
import QuestionV2 from './QuestionV2';

function Assessment(props) {
    const { questionVersion, ...rest } = props;
    return (
        questionVersion === "1"
            ? <QuestionV1 {...rest} />
            : <QuestionV2 {...rest} />
    )
}

export default Assessment