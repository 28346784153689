import React, { useState, useEffect } from 'react';
import { useSignIn, useAuthUser } from 'react-auth-kit'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input, Modal, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MainLogin from '../layouts/MainLogin';
import axios from 'axios';


export default function Login() {
    const signIn = useSignIn()
    const navigate = useNavigate()
    const auth = useAuthUser()
    useEffect(() => {
        if (auth()) {
            navigate("/home")
        }
    }, [auth, navigate])

    const [state, setState] = useState({
        showTerms: false,
        btnLoginLoading: false,
    })

    const [loginErrorMsg, setLoginErrorMsg] = useState("")
    const onLogin = (formData) => {
        setState(prev => ({
            ...prev,
            btnLoginLoading: true,
        }))
        axios.post(`/LoginVRMS/LoginwithJWTToken`, {
            Email: formData.email,
            Password: formData.password,
        }).then(res => {
            let resData = res.data
            setState(prev => ({
                ...prev,
                btnLoginLoading: false,
            }))
            if (res.data.Status === "True") {
                signIn({
                    token: resData.Data.token,
                    expiresIn: resData.Data.expiresIn,
                    tokenType: "Bearer",
                    authState: {
                        userId: resData.Data.userId,
                        fullName: resData.Data.fullName,
                        email: resData.Data.email,
                        role: resData.Data.role,
                    }
                })
                // window.location.href = "/home"
                navigate("/home")
            } else {
                setLoginErrorMsg(res.data.Message)
            }
        }).catch(error => {
            setState(prev => ({
                ...prev,
                btnLoginLoading: false,
            }))
            console.log(error)
            setLoginErrorMsg(error.message)
        })
    };

    return (
        <>
            <MainLogin
                title="Log In"
                subtitle="Enter your email address and password below to log in."
            >
                <Form
                    name="login"
                    layout="vertical"
                    autoComplete="off"
                    size="middle"
                    requiredMark={false}
                    onFinish={onLogin}
                >
                    {loginErrorMsg !== ""
                        && < Alert message={loginErrorMsg} type="error" showIcon style={{ marginRight: "25px", marginBottom: "20px" }} />
                    }
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Email Address"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                            size="large"
                        />
                    </Form.Item>
                    <div className="i-x-space-between i-y-center">
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <div className="login-form-link">
                                <Link to="/forgotpassword">Forgot password</Link>
                            </div>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button className="d-100 m-100 login-form-button" type="primary" size="large" htmlType="submit"
                            loading={state.btnLoginLoading}
                        >
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
                <div className="login-terms">
                    By logging in, you are agreeing to the <Button type="link" style={{ padding: "unset", fontSize: "13px", height: "unset" }}
                        onClick={() => setState(prev => ({
                            ...prev,
                            showTerms: true,
                        }))}
                    >Terms of Use</Button>. For further assistance, please contact our helpdesk at <a href="mailto:agorahelpdesk@strateqgroup.com"><Button type="link" style={{ padding: "unset", fontSize: "13px", height: "unset" }}>agorahelpdesk@strateqgroup.com</Button></a>
                    <Modal open={state.showTerms}
                        footer={null}
                        width="1000px"
                        onCancel={() => setState(prev => ({
                            ...prev,
                            showTerms: false,
                        }))}
                    >
                        <div className="login-terms-title">Terms of Use</div>
                        <div className="login-terms-body">
                            <TermsOfUseText />
                        </div>
                    </Modal>
                </div>
            </MainLogin>
            <style>{`
                .login-terms{
                    text-align: center;
                    color: #757575;
                }
                .login-terms-title{
                    font-size: 20px;
                    font-weight: bold;
                    border-bottom: 1px solid gainsboro;
                    padding-bottom: 10px;
                    margin-bottom: 10px
                }
                .login-terms-body{
                    text-align: justify;
                }
            `}</style>
        </>
    )
}

const TermsOfUseText = () => {
    return (
        <>
            <Typography.Text>Please read these terms of use ("terms of use", "agreement") carefully before using the AGORA website (“website”, "service") operated by Strateq ("us", 'we", "our").</Typography.Text>

            <Typography.Title level={5}>Disclaimer</Typography.Title>

            <Typography.Paragraph> AGORA does not promise that the website or any content, service or feature of the site will be error-free or uninterrupted, or that any defects will be corrected, or that your use of the site will provide specific results.</Typography.Paragraph>

            <Typography.Paragraph>The website and its content are delivered on an “AS-IS: and “AS-AVAILABLE” basis.</Typography.Paragraph>

            <Typography.Paragraph>All information provided on the website is subject to change without notice.</Typography.Paragraph>

            <Typography.Paragraph>AGORA cannot ensure that any files or other data you download from the website will be free of viruses or contamination or destructive features.</Typography.Paragraph>

            <Typography.Paragraph>AGORA disclaims all warranties, express or implied, including any warranties or accuracy, non-infringement, merchantability and fitness for a particular purpose.</Typography.Paragraph>

            <Typography.Paragraph>AGORA disclaims any and all liability for the acts, omissions and conduct of any third parties in connection with or related to your use of the website. You assume all responsibility for your use of the website and any linked sites.</Typography.Paragraph>

            <Typography.Paragraph>The above disclaimer applies to any damages, liability or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence or any other cause of action.</Typography.Paragraph>

            <Typography.Title level={5}>Conditions of use</Typography.Title>

            <Typography.Paragraph>By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. Strateq only grants use and access of this website, its products, and its services to those who have accepted its terms.</Typography.Paragraph>

            <Typography.Paragraph>Solely these Terms of Use apply with respect to the AGORA. By accessing and using this site, you accept the following Terms and Conditions of Use (“TOU”), without limitation or qualification. You expressly agree and acknowledge that You are subject to, bound by, and will comply with these Terms of Use. By using the AGORA Sourcing, you agree to these Terms of Use; if you do not agree, do not access or use the AGORA Sourcing.</Typography.Paragraph>

            <Typography.Title level={5}>Privacy policy</Typography.Title>

            <Typography.Paragraph>Before you continue using our website, we advise you to read our privacy policy [link to privacy policy] regarding our user data collection. It will help you better understand our practices.</Typography.Paragraph>

            <Typography.Title level={5}>Age restriction</Typography.Title>

            <Typography.Paragraph>You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. Strateq assumes no responsibility for liabilities related to age misrepresentation.</Typography.Paragraph>

            <Typography.Title level={5}>Property Rights</Typography.Title>

            <Typography.Paragraph>The pages and content on this website are subject to protection under applicable copyright and trademark laws, and all copyright information, trademarks and logos must remain intact. AGORA grants you a non-exclusive, limited permission to access and display the pages on this Website as a current or potential customer, vendor, or partner of AGORA, or for non-commercial research purposes, provided you comply with these Terms of Use. All other use of this Website is prohibited.</Typography.Paragraph>

            <Typography.Paragraph>Your failure to comply with such terms or any of the terms on this website will result in automatic termination of any rights granted to you, without prior notice, and you must immediately destroy all copies of downloaded materials in your possession, custody or control. You may not use, reproduce, or distribute any of the copyrighted material, trademarks, logos, graphics, sounds, videos, images, or other proprietary content on this website without the express written permission of AGORA.</Typography.Paragraph>

            <Typography.Title level={5}>Intellectual property</Typography.Title>

            <Typography.Paragraph>You agree that all materials, products, and services provided on this website are the property of Strateq, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Strateq’s intellectual property in any way, including electronic, digital, or new trademark registrations.</Typography.Paragraph>

            <Typography.Paragraph>You grant Strateq a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</Typography.Paragraph>

            <Typography.Title level={5}>User accounts</Typography.Title>

            <Typography.Paragraph>As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.</Typography.Paragraph>

            <Typography.Paragraph>If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly.</Typography.Paragraph>

            <Typography.Paragraph>We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.</Typography.Paragraph>

            <Typography.Title level={5}>Applicable law</Typography.Title>

            <Typography.Paragraph>By visiting this website, you agree that the laws of the Malaysia, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Strateq and you, or its business partners and associates.</Typography.Paragraph>

            <Typography.Title level={5}>Termination</Typography.Title>

            <Typography.Paragraph>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms of Use.</Typography.Paragraph>

            <Typography.Paragraph>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</Typography.Paragraph>

            <Typography.Title level={5}>Disputes</Typography.Title>

            <Typography.Paragraph>Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court Malaysia & Singapore and you consent to exclusive jurisdiction and venue of such courts.</Typography.Paragraph>

            <Typography.Title level={5}>Indemnification</Typography.Title>

            <Typography.Paragraph>You agree to indemnify Strateq and its affiliates and hold Strateq harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.</Typography.Paragraph>

            <Typography.Title level={5}>Limitation on liability</Typography.Title>

            <Typography.Paragraph>Strateq is not liable for any damages that may occur to you as a result of your misuse of our website.</Typography.Paragraph>

            <Typography.Paragraph>Strateq reserves the right to edit, modify, and change this Agreement any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between Strateq and the user, and this supersedes and replaces all prior agreements regarding the use of this website.</Typography.Paragraph>

        </>
    )
}

