import Login from "../pages/Login";
import Registration from "../pages/Form/Registration";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";

const AppRoutes = [
    {
        index: true,
        element: <Login />,
        authentication: false
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        authentication: false
    },
    {
        path: '/resetpassword/:userid/:tempPassword',
        element: <ResetPassword />,
        authentication: false
    },
    {
        path: '/form/registration/:urlId',
        element: <Registration type="register" />,
        authentication: false
    }
];

export default AppRoutes;
